/* eslint-disable react/jsx-props-no-spreading */
import Icon from '@ant-design/icons';
import { FC } from 'react';

const SVG:FC = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.7502 5.66665H12.0418V4.24998C12.0418 2.29498 10.4552 0.708313 8.50016 0.708313C6.54516 0.708313 4.9585 2.29498 4.9585 4.24998V5.66665H4.25016C3.471 5.66665 2.8335 6.30415 2.8335 7.08331V14.1666C2.8335 14.9458 3.471 15.5833 4.25016 15.5833H12.7502C13.5293 15.5833 14.1668 14.9458 14.1668 14.1666V7.08331C14.1668 6.30415 13.5293 5.66665 12.7502 5.66665ZM8.50016 12.0416C7.721 12.0416 7.0835 11.4041 7.0835 10.625C7.0835 9.84581 7.721 9.20831 8.50016 9.20831C9.27933 9.20831 9.91683 9.84581 9.91683 10.625C9.91683 11.4041 9.27933 12.0416 8.50016 12.0416ZM10.696 5.66665H6.30433V4.24998C6.30433 3.03873 7.28891 2.05415 8.50016 2.05415C9.71141 2.05415 10.696 3.03873 10.696 4.24998V5.66665Z" fill="#807FA5" />
  </svg>
);

const AccountPasswordIcon: FC = (props) => <Icon component={SVG} {...props} />;

export default AccountPasswordIcon;
