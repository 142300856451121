/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { DeleteOutlined } from '@ant-design/icons';
import {
  Table, Row, Col, Popconfirm, InputNumber, Space,
} from 'antd';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { formatMoney } from '../../common/hepler';
import CartHook from '../../hooks/cart-hook';
import { ICart, ICartProduct, IUpdateCart } from '../../interface/Cart';
import { IProduct } from '../../interface/Product';

import './index.scss';

type ITableCart = {
  cart: ICart,
  onRowSelection: (cart: ICart | undefined) => void
  errorTable: FieldError | undefined
}

const TableCart = ({
  cart, onRowSelection, errorTable,
}: ITableCart): JSX.Element => {
  const [tableSelectedRows, _tableSelectedRows] = useState<ICartProduct[]>([]);
  const rowSelection = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      _tableSelectedRows(selectedRows);
    },
    getCheckboxProps: (record: ICartProduct) => ({
      disabled: !record.available, // Column configuration not to be checked
    }),
  };

  useEffect(() => {
    onRowSelection({
      storeName: cart.storeName,
      list: tableSelectedRows,
      minimumStore: cart.minimumStore,
      lowerMinimumStore: cart.lowerMinimumStore,
    });
  }, [tableSelectedRows]);

  useEffect(() => {
    const list: ICartProduct[] = tableSelectedRows.map((q) => {
      const updateCart: ICartProduct = {
        ...q,
        amount: cart.list.find((x) => x._id === q._id)?.amount || 0,
      };
      return updateCart;
    });
    _tableSelectedRows(list);
  }, [cart]);

  const columns = [
    {
      title: 'สินค้า',
      width: '280px',
      render: (prop: ICartProduct) => (
        <div>
          <Row gutter={[12, 24]} wrap={false}>
            <Col flex="70px">
              <div style={{
                backgroundImage: `url(${prop.product?.images[0]?.url || ''})`, backgroundSize: 'cover', width: 70, height: 70,
              }}
              />
            </Col>
            <Col flex="160px">
              <Row>
                <Col span={24}>
                  <div className="product_name">
                    {prop?.product.product}
                  </div>
                </Col>
                <Col span={24}>
                  <div className="product_varient">
                    {prop.variantSaleInfo?.var1?.name || 'ไม่มีสินค้านี้แล้ว'}
                  </div>
                  <div className="product_varient">
                    {prop.variantSaleInfo?.var2?.name || ''}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ),
    },
    {
      title: 'ราคา',
      className: 'text-center',
      render: (prop: any) => (
        <div>
          {prop.available ? `${prop?.variantSaleInfo.price} บาท` : '-'}
        </div>
      ),
    },
    {
      title: 'จำนวน',
      className: 'text-center',
      render: (prop: ICartProduct) => {
        if (prop.available === false) return '-';
        const { updateToCart } = CartHook();
        const productStock = prop.variantSaleInfo.stock;
        const productMin = prop.variantSaleInfo.minimumOrder;
        const cartId = prop._id;
        const [amount, _amount] = useState(prop.amount);

        const updateCartDebounce = useCallback(debounce(async (updateAmount: number) => {
          const formData: IUpdateCart = {
            cartId,
            amount: updateAmount,
          };
          await updateToCart(formData);
        }, 1000), []);

        const onChange = (e: any) => {
          _amount(e);
          updateCartDebounce(e);
        };
        return <InputNumber size="large" onChange={onChange} value={amount} style={{ textAlign: 'center' }} min={productMin} max={productStock} />;
      },
    },
    {
      title: 'ราคารวม',
      className: 'text-center',
      render: (prop: ICartProduct) => <div>{prop.available ? formatMoney(prop.amount * prop.variantSaleInfo.price, 2) : '-'}</div>,
    },
    {
      title: '',
      render: (prop: IProduct) => {
        const { deleteInCart } = CartHook();
        const confirm = async () => {
          await deleteInCart(prop._id);
          if (cart.list.length === 1) {
            onRowSelection({
              storeName: cart.storeName,
              list: [],
              minimumStore: cart.minimumStore,
              lowerMinimumStore: cart.lowerMinimumStore,
            });
          }
        };
        return (
          <Popconfirm placement="topLeft" title="ต้องการนำสินค้าออกจากตะกร้า ?" onConfirm={confirm} okText="ตกลง" cancelText="ยกเลิก">
            <DeleteOutlined style={{ fontSize: 18, color: 'black', fontWeight: 800 }} />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <div className="table_cart">
      <div style={{ fontSize: '18px' }} className="head-table">{cart.storeName}</div>
      {errorTable === undefined ? null : (
        <Space className="error__input__text" align="center">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.839 12.7906L9.24928 1.35332C9.12985 1.1462 8.95034 0.972719 8.73019 0.85168C8.51005 0.730641 8.25765 0.666656 8.00035 0.666656C7.74305 0.666656 7.49066 0.730641 7.27051 0.85168C7.05036 0.972719 6.87085 1.1462 6.75143 1.35332L0.160975 12.7906C0.0483995 12.9859 -0.00681482 13.2043 0.000671534 13.4247C0.00815789 13.6451 0.0780916 13.86 0.20371 14.0487C0.329328 14.2373 0.506381 14.3933 0.717746 14.5016C0.92911 14.6098 1.16764 14.6667 1.41026 14.6667H14.5897C14.8324 14.6667 15.0709 14.6098 15.2823 14.5016C15.4936 14.3933 15.6707 14.2373 15.7963 14.0487C15.9219 13.86 15.9918 13.6451 15.9993 13.4247C16.0068 13.2043 15.9516 12.9859 15.839 12.7906ZM7.29499 5.3727C7.29499 5.20276 7.36927 5.03979 7.50148 4.91962C7.6337 4.79946 7.81302 4.73196 8 4.73196C8.18698 4.73196 8.3663 4.79946 8.49852 4.91962C8.63073 5.03979 8.70501 5.20276 8.70501 5.3727V9.21715C8.70501 9.38708 8.63073 9.55006 8.49852 9.67022C8.3663 9.79038 8.18698 9.85789 8 9.85789C7.81302 9.85789 7.6337 9.79038 7.50148 9.67022C7.36927 9.55006 7.29499 9.38708 7.29499 9.21715V5.3727ZM8.03525 12.7476H8.01551C7.73748 12.7468 7.47056 12.6484 7.27058 12.4728C7.0706 12.2973 6.95304 12.0582 6.94248 11.8057C6.9374 11.6802 6.95991 11.5549 7.00869 11.4373C7.05747 11.3198 7.13155 11.2122 7.22659 11.1209C7.32163 11.0296 7.43574 10.9565 7.56224 10.9058C7.68874 10.855 7.82509 10.8277 7.96334 10.8254H7.98308C8.26105 10.8257 8.52813 10.9237 8.72839 11.0989C8.92866 11.2741 9.0466 11.5129 9.05752 11.7654C9.06299 11.8912 9.04071 12.0168 8.99201 12.1348C8.94331 12.2527 8.86916 12.3606 8.77392 12.4521C8.67868 12.5436 8.56427 12.6169 8.43742 12.6677C8.31057 12.7184 8.17383 12.7456 8.03525 12.7476Z" fill="#D64E30" />
          </svg>
          <div>
            ไม่สามารถสั่งซื้อสินค้าได้ เนื่องจากยอดขั้นต่ำสำหรับการสั่งซื้อคือ
            {' '}
            {formatMoney(cart.minimumStore, 0)}
            {' '}
            บาท
          </div>
        </Space>
      )}
      <Table
        pagination={false}
        rowSelection={{
          ...rowSelection,
        }}
        rowKey="_id"
        columns={columns}
        dataSource={cart.list}
      />
    </div>
  );
};

export default TableCart;
