/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import { useHistory } from 'react-router';
import { Button, Rate } from 'antd';
import { HeartFilled } from '@ant-design/icons';
import { IProduct } from '../../interface/Product';
import AuthHook from '../../hooks/auth-hook';
import './index.scss';
import { formatMoney } from '../../common/hepler';
import productHook from '../../hooks/product-hook';

type ICardProduct = {
  product: IProduct
  favId: string
  onUpdate: () => void
}
const CardProductsFav = ({ product, favId, onUpdate }:ICardProduct):JSX.Element => {
  const { unLikeProduct } = productHook();
  const { accessToken, setVisibleModalLogin } = AuthHook();
  const router = useHistory();
  const addToCart = async () => {
    if (!accessToken) {
      setVisibleModalLogin(true);
    } else {
      router.push(`/product/${product._id}`);
      window.scrollTo(0, 0);
    }
  };

  const imageUrl = product?.images[0]?.url || '';

  const onUnLikeClick = async () => {
    await unLikeProduct(favId, product._id);
    onUpdate();
  };

  const UnLike = () => (
    <div aria-hidden onClick={onUnLikeClick} className="Fav">
      <HeartFilled style={{ color: '#FF4A4A' }} />
    </div>
  );
  return (
    <div className="CardProducts">
      <div
        className="product_image"
        style={{ backgroundImage: `url(${imageUrl})` }}
        onClick={() => addToCart()}
      />
      <div className="Detail">
        <div className="ProductDetail">
          <p className="ProductName">
            {product.product}
          </p>
          {accessToken
            ? (
              <p className="Price">
                {formatMoney(product.minPrice, 0)}
                {' '}
                บาท
              </p>
            )
            : null}
          <Rate className="Rate" allowHalf disabled defaultValue={product.rate || 0} />
          <Button aria-hidden onClick={() => addToCart()}>
            เพิ่มไปยังรถเข็น
          </Button>
        </div>
        {accessToken
          ? (
            <UnLike />
          )
          : null}
      </div>
    </div>
  );
};

export default CardProductsFav;
