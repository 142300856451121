/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import {
  Col, Rate, Row, Space,
} from 'antd';
import { formatDateTime } from '../../common/hepler';
import AccountIcon from '../../common/icons/account_icon';
import LikeIcon from '../../common/icons/like_icon';
import StarFadeIcon from '../../common/icons/start_fade_icon';
import StarIcon from '../../common/icons/star_icon';
import productHook from '../../hooks/product-hook';
import { IProductReview } from '../../interface/ProductReview';

import './index.scss';

const ProductReviews = ({ review }:{review:IProductReview}) :JSX.Element => {
  const { likeReview, unLikeReview } = productHook();

  const onLikeClick = async () => {
    await likeReview(review._id);
  };

  const onUnLikeClick = async () => {
    await unLikeReview(review._id);
  };

  return (
    <div className="product__views">
      <Row gutter={[13, 24]} wrap={false}>
        <Col flex="30px">
          <AccountIcon />
        </Col>
        <Col flex="auto">
          <Row gutter={[13, 24]}>
            <Col span={24}>
              <div>
                {review.retailerId.firstName}
                {' '}
                {review.retailerId.lastName}
              </div>
              <Rate allowHalf disabled value={review.rate} style={{ fontSize: 14, color: '#535252' }} />
            </Col>
            <Col span={24}>
              {review.comment}
            </Col>
            <Col span={24}>
              <Space size={24}>
                {review.images.map((img, index) => <div className="img_reviews" style={{ backgroundImage: `url(${img.url})` }} />)}
              </Space>
            </Col>
          </Row>
        </Col>
        <Col flex="200px" className="control">
          <div>
            {formatDateTime(review.createdAt)}
          </div>
          {/* <div>
            <div onClick={onLikeClick}>
              <LikeIcon />
            </div>
            <div onClick={onUnLikeClick}>
              <LikeIcon />
            </div>
          </div> */}
        </Col>
      </Row>
    </div>
  );
};

export default ProductReviews;
