/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ICategory, IFavProduct, IFavProductDetail, IFavProductDetailResponse, IProduct, IProductResponse, IUnit,
} from '../../interface/Product';
import { IProductReview, IProductReviewResponse } from '../../interface/ProductReview';
// Define a type for the slice state
interface ProductState {
  products: IProduct[];
  favProducts: IFavProductDetail[]
  totalFav:number;
  total: number;
  totalReviews: number;
  categorys: ICategory[]
  units:IUnit[]
  productReviews: IProductReview[]
  isLoading: boolean
}

// Define the initial state using that type
const initialState: ProductState = {
  products: [],
  favProducts: [],
  totalFav: 0,
  total: 0,
  totalReviews: 0,
  categorys: [],
  units: [],
  productReviews: [],
  isLoading: false,
};

export const productSlice = createSlice({
  name: 'product',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setProduct(state, action: PayloadAction<IProductResponse>) {
      return {
        ...state,
        products: action.payload.docs,
        total: action.payload.total,
      };
    },
    setFavProduct(state, action: PayloadAction<IFavProductDetailResponse>) {
      return {
        ...state,
        favProducts: action.payload.docs,
        totalFav: action.payload.total,
      };
    },
    addProduct(state, action: PayloadAction<IProductResponse>) {
      const update = [...state.products, ...action.payload.docs];
      return {
        ...state,
        products: update,
        total: action.payload.total,
      };
    },
    addFavProduct(state, action: PayloadAction<IFavProductDetailResponse>) {
      const update = [...state.favProducts, ...action.payload.docs];
      return {
        ...state,
        favProducts: update,
        totalFav: action.payload.total,
      };
    },
    setProductReview(state, action: PayloadAction<IProductReviewResponse>) {
      return {
        ...state,
        productReviews: action.payload.docs,
        totalReviews: action.payload.total,
      };
    },
    setCategory(state, action:PayloadAction<ICategory[]>) {
      return {
        ...state,
        categorys: action.payload,
      };
    },
    setUnit(state, action:PayloadAction<IUnit[]>) {
      return {
        ...state,
        units: action.payload,
      };
    },
    setLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    setProductFavorite(state, action:PayloadAction<IFavProduct[]>) {
      const { products } = state;
      const favProd = action.payload;
      const productsWithFav = products.map((prod) => {
        const findFav = favProd.find((fp) => prod._id === fp.productId);
        if (findFav) {
          return { ...prod, isFav: findFav._id };
        }
        return { ...prod, isFav: undefined };
      });
      return {
        ...state,
        products: productsWithFav,
      };
    },
  },
});

export const {
  setProduct,
  setProductReview,
  setCategory,
  setUnit,
  setLoading,
  setProductFavorite,
  setFavProduct,
  addProduct,
  addFavProduct,
} = productSlice.actions;

export default productSlice.reducer;
