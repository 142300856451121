/* eslint-disable react/jsx-props-no-spreading */
import Icon from '@ant-design/icons';
import { FC } from 'react';

const SVG:FC = () => (
  <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.9583 2.62498H14.9075C14.4 1.22331 13.0708 0.208313 11.5 0.208313C9.92917 0.208313 8.6 1.22331 8.0925 2.62498H3.04167C1.7125 2.62498 0.625 3.71248 0.625 5.04165V21.9583C0.625 23.2875 1.7125 24.375 3.04167 24.375H19.9583C21.2875 24.375 22.375 23.2875 22.375 21.9583V5.04165C22.375 3.71248 21.2875 2.62498 19.9583 2.62498ZM11.5 2.62498C12.1646 2.62498 12.7083 3.16873 12.7083 3.83331C12.7083 4.4979 12.1646 5.04165 11.5 5.04165C10.8354 5.04165 10.2917 4.4979 10.2917 3.83331C10.2917 3.16873 10.8354 2.62498 11.5 2.62498ZM13.9167 19.5416H5.45833V17.125H13.9167V19.5416ZM17.5417 14.7083H5.45833V12.2916H17.5417V14.7083ZM17.5417 9.87498H5.45833V7.45831H17.5417V9.87498Z" fill="black" />
  </svg>

);

const NavListIcon: FC = (props) => <Icon component={SVG} {...props} />;

export default NavListIcon;
