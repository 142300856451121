/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
import {
  SearchOutlined,
} from '@ant-design/icons';
import {
  Col, Input, Row, Space, Button, Badge, Menu, Dropdown,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import LogoIcon from '../../common/icons/logo_icon';
import NavAccountIcon from '../../common/icons/nav_account_icon';
import NavListIcon from '../../common/icons/nav_list_icon';
import NavCartIcon from '../../common/icons/nav_cart_icon';
import NavHeartIcon from '../../common/icons/nav_heart_icon';
import AuthHook from '../../hooks/auth-hook';
import productHook from '../../hooks/product-hook';
import { ICategory } from '../../interface/Product';
import './index.scss';
import CartHook from '../../hooks/cart-hook';

const TopNavigation = (): JSX.Element => {
  const router = useHistory();

  const path = router.location.pathname;

  const {
    fetchCategory, categorys,
  } = productHook();
  const {
    accessToken, setVisibleModalLogin, setVisibleModalRegister, logout, clearToken,
  } = AuthHook();

  const { fetchCart, cartsItem } = CartHook();

  const fetchData = async () => {
    await fetchCategory();
    if (accessToken && router.location.pathname !== '/cart') {
      await fetchCart();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const loginClick = () => {
    setVisibleModalLogin(true);
  };

  const logoutClick = async () => {
    await logout();
    router.push('/');
    clearToken();
  };

  const [srchText, _srchText] = useState('');

  const registerClick = () => {
    setVisibleModalRegister(true);
  };

  const ActionNavNotLoggedIn = () => (
    <Row justify="space-around" align="middle">
      <Col>
        <Button size="large" className="loging_btn" type="text" ghost onClick={loginClick}>เข้าสู่ระบบ</Button>
      </Col>
      <Col>
        <Button size="large" className="register_btn" onClick={registerClick}>
          สมัครสมาชิก
        </Button>
      </Col>
    </Row>
  );

  const ActionNavLoggedIn = () => {
    const menu = (
      <Menu>
        <Menu.Item key="0">
          <Link to="/order">รายการสั่งซื้อ</Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="1">
          <Link to="/cart">รถเข็น</Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="4">
          <Link to="/favorite">รายการโปรด</Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="5">
          <a aria-hidden onClick={() => logoutClick()}>ออกจากระบบ</a>
        </Menu.Item>
      </Menu>
    );
    return (
      <Row justify="space-between" align="middle">
        <Col>
          <button type="button" className="button__icon" onClick={() => router.push('/order')}>
            <NavListIcon />
          </button>
        </Col>
        <Col>
          <button type="button" className="button__icon" onClick={() => router.push('/favorite')}>
            <NavHeartIcon />
          </button>
        </Col>
        <Col style={{ display: 'flex' }}>
          <Badge color="#1BD27E" count={cartsItem} offset={[-24, 1]} style={{ border: 'none' }}>
            <button type="button" className="button__icon" onClick={() => router.push('/cart')}>
              <NavCartIcon />
            </button>
          </Badge>
        </Col>
        <Col>
          <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" arrow overlayStyle={{ width: '180px', color: '#4A5463', fontFamily: 'Poppins' }}>
            <button type="button" className="button__icon" onClick={(e) => e.preventDefault()}>
              <NavAccountIcon />
            </button>
          </Dropdown>
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    const haveKeyword = router.location.search.includes('?keyword');
    if (!haveKeyword) {
      _srchText('');
    }
  }, [path]);

  const searchProduct = useCallback(
    debounce(async (text:string) => {
      if (path.includes('/category')) {
        router.push(`${path}?keyword=${text}`);
      } else {
        router.push(`/category/all?keyword=${text}`);
      }
    }, 1000),
    [path],
  );

  const menu = (
    <Menu>
      {categorys.map((m:ICategory, index:number) => {
        const key:React.Key = `menu_sub_${index}`;
        return (
          <div key={key}>
            <Menu.Item>
              <Link style={{ color: '#4A5463', fontSize: 14 }} to={`/category/${m._id}`}>
                {m.name}
              </Link>
            </Menu.Item>
            {index < categorys.length - 1
              ? <Menu.Divider /> : null}
          </div>
        );
      })}
    </Menu>
  );

  return (
    <div className="nav__bar">
      <Row gutter={[40, 18]} align="middle" justify="center">
        <Col flex="155px">
          <Link to="/">
            <LogoIcon />
          </Link>
        </Col>
        <Col flex="auto">
          <Input
            value={srchText}
            onChange={(e) => {
              _srchText(e.target.value);
              searchProduct(e.target.value);
            }}
            style={{ borderColor: '#d9d9d9', boxShadow: 'none' }}
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
        <Col flex="270px">
          {accessToken === undefined ? <ActionNavNotLoggedIn /> : <ActionNavLoggedIn />}
        </Col>
        <Col span={24}>
          <Row gutter={[40, 18]} wrap={false}>
            <Col flex="155px" />
            <Col flex="auto">
              <Space size={6} className="categoryBar" wrap={false}>
                <Dropdown overlay={menu} placement="bottomRight" overlayClassName="category__dropdown">
                  <Button type="text" style={{ minWidth: 70, backgroundColor: path === '/category/all' ? '#E5E5E5' : '' }} onClick={() => router.push('/category/all')}>
                    <div style={{ color: '#4A5463', fontSize: 12 }}>
                      หมวดหมู่ทั้งหมด
                    </div>
                  </Button>
                </Dropdown>
                {categorys.map((m:ICategory, index:number) => {
                  const key:React.Key = `menu_${index}`;
                  return (
                    <Button style={{ minWidth: 70, backgroundColor: path === `/category/${m._id}` ? '#E5E5E5' : '' }} key={key} type="text" onClick={() => router.push(`/category/${m._id}`)}>
                      <div style={{ color: '#4A5463', fontSize: 12 }}>
                        {m.name}
                      </div>
                    </Button>
                  );
                })}
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default TopNavigation;
