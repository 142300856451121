/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import { AxiosResponse } from 'axios';
import {
  ICategory, ICreateProduct, IFavProduct, IFavProductDetailResponse, IProduct, IProductDetail, IProductResponse, IUnit, IUpdateProduct,
} from '../interface/Product';
import $axios from '../plugin/axios';

export const getProduct = (
  query: string,
  page: number,
  size: number,
  sort: string,
):Promise<AxiosResponse<IProductResponse>> => {
  const response = $axios.get(`/product/retailer-search?${query}&page=${page}&size=${size}&sort=${sort}`);
  return response;
};

export const getProductRecommend = ():Promise<AxiosResponse<IProductResponse>> => {
  const response = $axios.get('/product/recommended');
  return response;
};

export const findProduct = (id: string):Promise<AxiosResponse<IProductDetail>> => {
  const response = $axios.get(`/product/retailer-detail/${id}`);
  return response;
};

export const create = (form:ICreateProduct):Promise<AxiosResponse<IProduct>> => {
  const response = $axios.post('/product/wholesaler', form);
  return response;
};

export const update = (form:IUpdateProduct):Promise<AxiosResponse<IProduct>> => {
  const response = $axios.put('/product/wholesaler', form);
  return response;
};

export const getCategory = ():Promise<AxiosResponse<ICategory[]>> => {
  const response = $axios.get('/category/dashboard');
  return response;
};

export const getUnit = ():Promise<AxiosResponse<IUnit[]>> => {
  const reponse = $axios.get('/unit');
  return reponse;
};

export const getFavorite = ():Promise<AxiosResponse<IFavProduct[]>> => {
  const response = $axios.get('/favorite');
  return response;
};

export const getFavoriteDetail = (
  page: number,
  size: number,
):Promise<AxiosResponse<IFavProductDetailResponse>> => {
  const response = $axios.get(`/favorite/product-detail?page=${page}&size=${size}`);
  return response;
};

export const like = (productId:string):Promise<AxiosResponse> => {
  const response = $axios.post('/favorite', { productId });
  return response;
};

export const unLike = (favoriteId:string, productId:string):Promise<AxiosResponse> => {
  const response = $axios.delete('/favorite', {
    data: {
      favoriteId,
      productId,
    },
  });
  return response;
};
