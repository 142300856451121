/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-unused-expressions */
import {
  Button, Carousel, Col, Row,
} from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import CardCategory from '../../../components/CardCategory';
import productHook from '../../../hooks/product-hook';
import './index.scss';

const Categories = (): JSX.Element => {
  const { categorys } = productHook();
  const slideToShow = 6;
  let caroLength = parseInt(String(categorys.length / slideToShow), 0);
  const mod = categorys.length % slideToShow;
  if (mod > 0) {
    caroLength += 1;
  }

  const CarouselChild = ({ page } : {page:number}) :JSX.Element => {
    const sliceStart = page * slideToShow;
    const sliceEnd = sliceStart + slideToShow;
    const cateForChild = categorys.slice(sliceStart, sliceEnd);
    return (
      <Row gutter={[18, 18]} wrap>
        {
          cateForChild.map((cas, index) => (
            <Col span={8} key={`${page}__${String(index)}__cass`}>
              <CardCategory category={cas} />
            </Col>
          ))
        }
      </Row>
    );
  };

  const CarouselCategory = ():JSX.Element => {
    const refCa:React.Ref<CarouselRef> = React.createRef();
    const carouselChildren = [];
    for (let i = 0; i < caroLength; i++) {
      carouselChildren.push(<CarouselChild page={i} key={`carouselChildren__${String(i)}`} />);
    }

    const prev = () => {
      if (refCa.current !== null) {
        refCa.current.prev();
      }
    };

    const next = () => {
      if (refCa.current !== null) {
        refCa.current.next();
      }
    };
    return (
      <Row wrap={false} gutter={[40, 0]} justify="space-between" align="middle">
        <Col flex="40px" style={{ textAlign: 'center' }}><Button size="large" shape="circle" className="circle_btn" onClick={prev}>&lt;</Button></Col>
        <Col flex="auto">
          <Carousel ref={refCa}>
            {carouselChildren.map((q) => q)}
          </Carousel>
        </Col>
        <Col flex="40px" style={{ textAlign: 'center' }}><Button size="large" shape="circle" className="circle_btn" onClick={next}>&gt;</Button></Col>
      </Row>

    );
  };
  return (
    <div className="Categories">
      <div className="Header">
        <h2>
          หมวดหมู่
          &nbsp;
        </h2>
        {/* <Link className="LinkAllCategories" to="/">
          หมวดหมู่ทั้งหมด &nbsp;
          <b>&gt;</b>
        </Link> */}
      </div>
      <CarouselCategory />
    </div>
  );
};

export default Categories;
