/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-template */
import { useHistory } from 'react-router';
import { ICategory } from '../../interface/Product';
import './index.scss';

const CardCategory = ({ category }: {category:ICategory}):JSX.Element => {
  const router = useHistory();
  return (
    <div
      aria-hidden
      className="CardCategory"
      onClick={() => {
        window.scroll(0, 0);
        router.push('/category/' + category._id);
      }}
    >
      <div className="Left">
        <p className="Category">{category.name}</p>
        <p className="Product">
          {category.total}
          {' '}
          รายการสินค้า
        </p>
        <p aria-hidden className="Shop">+ ซื้อเลย</p>
      </div>
      <div className="Right" style={{ backgroundImage: `url(${category.image.url || ''})` }} />
    </div>
  );
};
export default CardCategory;
