/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  ComponentType, FC,
} from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import BottomNavigation from '../../components/BottomNavigation';
import TopNavigation from '../../components/TopNavigation';
import AuthHook from '../../hooks/auth-hook';

import './index.scss';

type ILayoutProp = {children:ComponentType}

const Layout:FC<ILayoutProp> = ({ children: Content }:ILayoutProp) => (
  <>
    <div className="layout__component">
      <TopNavigation />
      <section>
        <Content />
      </section>
    </div>
    <BottomNavigation />

  </>
);

const LayoutEmpty:FC<ILayoutProp> = ({ children: Content }:ILayoutProp) => (
  <Content />
);

type IRouteWithAuthProp = {
    component: React.ComponentType<unknown>,
    path: string
    layout?: boolean
}

const RouteWithAuth:FC<IRouteWithAuthProp> = ({ component, path, layout = true }:IRouteWithAuthProp) => {
  const { accessToken, setVisibleModalLogin } = AuthHook();
  if (!accessToken) {
    setVisibleModalLogin(true);
    return (
      <Redirect to="/" />
    );
  }

  return (
    <Route
      exact
      path={path}
      render={() => (
        <>
          {layout
            ? <Layout>{component}</Layout>
            : <LayoutEmpty>{component}</LayoutEmpty>}
        </>
      )}
    />
  );
};

export default RouteWithAuth;
