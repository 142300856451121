/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
import { Button, Col, Row } from 'antd';
import { FC } from 'react';
import { formatMoney } from '../../common/hepler';
import { IOrderDetail } from '../../interface/Order';
import './index.scss';

type IStepOrderReceipt = {
  onNextStep: () => void
  onGoHomePage: () => void
  order: IOrderDetail | undefined
}

const renderDate = (str: string) => {
  const dd = str.substring(0, 2);
  const mm = str.substring(2, 4);
  const yy = str.substring(4, 8);
  return `${dd}/${mm}/${yy}`;
};

const renderTime = (str: string) => {
  const hh = str.substring(0, 2);
  const mm = str.substring(2, 4);
  return `${hh}:${mm}`;
};

const StepOrderReceipt: FC<IStepOrderReceipt> = ({ onNextStep, onGoHomePage, order }: IStepOrderReceipt) => (
  <div className="step__receipt">
    <div>
      <div className="title_thank">ขอบคุณสำหรับการสั่งซื้อ</div>
    </div>
    <div className="information">
      <div>
        รายละเอียดคำสั่งซื้อ
      </div>
      <div>
        <Row gutter={[0, 33]}>
          <Col span={8} className="head__text">
            เลขที่คำสั่งซื้อ:
          </Col>
          <Col span={16} className="value__text">
            Invoice-
            {order?._id}
          </Col>
          <Col span={8} className="head__text">
            ชื่อร้านค้า:
          </Col>
          <Col span={16} className="value__text">
            {order?.wholesalerStoreName}
          </Col>
          <Col span={8} className="head__text">
            เบอร์โทรร้านค้า:
          </Col>
          <Col span={16} className="value__text">
            {order?.wholesalerDetail.phoneNumber}
          </Col>
          <Col span={8} className="head__text">
            บัญชีการรับเงิน:
          </Col>
          <Col span={16} className="value__text">
            <div>
              {order?.paymentInfo.brandName}
            </div>
            <div>
              เลขที่บัญชี :
              {' '}
              {order?.paymentInfo.accountNumber}
            </div>
            <div>
              สาขา :
              {' '}
              {order?.paymentInfo.branch}
            </div>
            <div>
              ชื่อบัญชี :
              {' '}
              {order?.paymentInfo.accountName}
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <div className="information">
      <div>
        รายละเอียดการชำระเงิน
      </div>
      <div>
        <Row gutter={[0, 33]}>
          <Col span={8} className="head__text">
            ชื่อผู้ซื้อ:
          </Col>
          <Col span={16} className="value__text">
            {order?.retailerDetail.firstName}
            {' '}
            {order?.retailerDetail.lastName}
          </Col>
          <Col span={8} className="head__text">
            เบอร์โทรลูกค้า:
          </Col>
          <Col span={16} className="value__text">
            {order?.retailerDetail.phoneNumber}
          </Col>
          <Col span={8} className="head__text">
            ที่อยู่การจัดส่ง:
          </Col>
          <Col span={16} className="value__text">
            <div>
              {order?.retailerAddress.address}
              {' '}
              {order?.retailerAddress.province.name}
              {' '}
              {order?.retailerAddress.country.name}
              {' '}
              {order?.retailerAddress.postalCode}
              {' '}
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <div className="confirm__payment__page">
      <div>
        <Row gutter={[12, 36]} className="body">

          <Col span="24">
            <Row gutter={[27, 27]}>
              <Col span={24} xs={24} md={14}>
                <Row gutter={[0, 42]}>
                  <Col span={24}>
                    <div className="box__detail">
                      <div className="box__head">1. รายละเอียดการจัดส่ง</div>
                      <div className="box__body">
                        <div>
                          {order?.shippingName}
                        </div>
                        <div>
                          เบอร์โทรขนส่ง :
                          {' '}
                          {order?.shippingPhoneNumber}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="box__detail">
                      <div className="box__head">2. ยอดรวม</div>
                      <div className="box__body">
                        <Row justify="space-between">
                          <Col>สินค้่า</Col>
                          <Col>
                            {order?.orders.reduce((prev, cur) => prev + cur.productTotalPrice, 0)}
                            {' '}
                            บาท
                          </Col>
                        </Row>
                        <Row justify="space-between" style={{ marginTop: 9 }}>
                          <Col>ค่าจัดส่ง</Col>
                          <Col>
                            {order?.shippingFee}
                            {' '}
                            บาท
                          </Col>
                        </Row>
                        <Row justify="space-between" style={{ fontWeight: 500, marginTop: 12 }}>
                          <Col>Total:</Col>
                          <Col>
                            {' '}
                            {order?.totalPrice}
                            {' '}
                            บาท
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="box__detail">
                      <div className="box__head">3. รายละเอียดการชำระเงิน</div>
                      <div className="box__body">
                        <div>
                          {order?.paymentInfo.brandName}
                        </div>
                        <div>
                          เลขที่บัญชี :
                          {' '}
                          {order?.paymentInfo.accountNumber}
                        </div>
                        <div>
                          สาขา :
                          {' '}
                          {order?.paymentInfo.branch}
                        </div>
                        <div>
                          ชื่อบัญชี :
                          {' '}
                          {order?.paymentInfo.accountName}
                        </div>
                        <div>
                          Date :
                          {' '}
                          {renderDate(order?.transferDate || '00000000')}
                        </div>
                        <div>
                          Time :
                          {' '}
                          {renderTime(order?.transferTime || '0000')}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24} xs={24} md={10}>
                <img src={order?.informPaymentSlip.url} alt="slip" style={{ width: '100%', height: '100%' }} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
    <div className="information">
      <div>
        รายละเอียดการจัดส่ง
      </div>
      <div>
        <Row>
          <Col span={8} className="head__text">
            สถานะการจัดส่ง:
          </Col>
          <Col span={16} className="value__text">
            {order?.orderStatus === '5' || order?.orderStatus === '6' ? 'จัดส่งสำเร็จ' : 'รอการจัดส่ง'}
          </Col>
        </Row>
      </div>
    </div>
    <div className="detail">
      <div>
        รายการสินค้า
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th style={{ paddingLeft: 24 }}>สินค้า</th>
              <th>จำนวน</th>
              <th>หน่วย</th>
              <th>ราคา</th>
              <th>ราคารวม</th>
            </tr>
          </thead>
          <tbody>
            {order?.orders.map((odrp, index) => (
              <tr key={String(index)}>
                <td style={{ paddingLeft: 24 }}>
                  <div className="product_name">
                    {odrp.product.product}
                  </div>
                  <div className="product_varient">
                    {odrp.variantSaleInfo.var1.name}
                  </div>
                  <div className="product_varient">
                    {odrp.variantSaleInfo.var2?.name || ''}
                  </div>
                </td>
                <td>
                  <div>{formatMoney(odrp.amount, 0)}</div>
                </td>
                <td>
                  <div>
                    {typeof (odrp.variantSaleInfo.unit) !== 'string' ? odrp.variantSaleInfo.unit.name : ''}
                  </div>
                </td>
                <td>
                  <div>{formatMoney(odrp.variantSaleInfo.price, 0)}</div>
                </td>
                <td>
                  <div>{formatMoney(odrp.productTotalPrice, 0)}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    <div className="btn_control">

      <Button onClick={onGoHomePage}>กลับไปหน้าหลัก</Button>

      <Button onClick={onNextStep}>หน้าคำสั้งซื้อ</Button>

    </div>
  </div>
);

export default StepOrderReceipt;
