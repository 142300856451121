/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import {
  Collapse, Modal,
} from 'antd';
import { useState } from 'react';
import { IOrderDetail } from '../../interface/Order';
import './index.scss';
import PanelReview from './panelReview';

type IModalReviewProp = {
  isModalVisible: boolean
  orderByID?: IOrderDetail
  handleOk: () => void
  handleCancel: () => void
  onReviewed: (orderId: string) => void
}

const { Panel } = Collapse;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const ModalReview = ({
  isModalVisible, orderByID, handleOk, handleCancel, onReviewed,
}: IModalReviewProp): JSX.Element => {
  const [activeKey, _activeKey] = useState('');
  function callback(key: string | string[]) {
    if (typeof (key) === 'string') {
      _activeKey(String(key));
    }
  }

  return (
    <Modal
      width="80%"
      style={{ maxWidth: '900px' }}
      title={null}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="modal_review"
      closeIcon={(
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M11.3139 9.89951L14.8494 6.36397L16.2636 7.77819L12.7281 11.3137L16.2636 14.8493L14.8494 16.2635L11.3139 12.7279L7.77836 16.2635L6.36415 14.8493L9.89968 11.3137L6.36415 7.77819L7.77836 6.36397L11.3139 9.89951Z" fill="black" />
        </svg>
      )}
    >
      <Collapse activeKey={activeKey} onChange={callback} className="collapse" expandIconPosition="right" destroyInactivePanel bordered={false} accordion>
        {orderByID?.reviews.map((q, index) => {
          const orderProduct = orderByID.orders.find((f) => f.product._id === q.productId);
          if (orderProduct === undefined) return null;
          return (
            <Panel header={orderProduct.product.product} key={String(index)} collapsible={q.reviewed ? 'disabled' : undefined}>
              <PanelReview orderProduct={orderProduct} orderId={orderByID._id} onCancel={() => _activeKey('')} onReviewed={() => onReviewed(orderByID._id)} />
            </Panel>
          );
        })}
      </Collapse>
    </Modal>
  );
};

export default ModalReview;
