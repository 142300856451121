/* eslint-disable react/jsx-props-no-spreading */
import Icon from '@ant-design/icons';
import { FC } from 'react';

const SVG:FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.67 3.77L17.9 2L8 11.9L17.9 21.8L19.67 20.03L11.54 11.9L19.67 3.77Z" fill="#44444F" />
  </svg>
);

const ArrowBackIcon: FC = (props) => <Icon component={SVG} {...props} />;

export default ArrowBackIcon;
