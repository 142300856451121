import React, { useEffect } from 'react';
import './index.scss';
import {
  Button, Col, Divider, Row,
} from 'antd';
import { useHistory } from 'react-router';
import CardProducts from '../../../components/CardProduct';
import productHook from '../../../hooks/product-hook';

const Recomend = (): JSX.Element => {
  const router = useHistory();
  const { fetchProductRecommed, products } = productHook();

  const fetchData = async () => {
    await fetchProductRecommed();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="Recommed">
      <div className="Header">
        <h2>
          สินค้าแนะนำ
        </h2>
      </div>
      <div className="Cards">
        <Row gutter={[22, 22]}>
          {
        products.map((product, index) => (
          <Col key={`product_rec_${String(index)}`} span={24} sm={8} lg={6}>
            <CardProducts product={product} />
          </Col>
        ))
      }
        </Row>
      </div>
      <div className="more__btn">
        <Divider>
          <Button onClick={() => {
            window.scrollTo(0, 0);
            router.push('/search?keyword=');
          }}
          >
            ดูเพิ่มเติม
          </Button>

        </Divider>
      </div>
    </div>
  );
};

export default Recomend;
