import { AxiosResponse } from 'axios';
import {
  IAddCart, ICart, ICartResponse, ISummaryCart, IUpdateCart,
} from '../interface/Cart';
import $axios from '../plugin/axios';

export const getCart = ():Promise<AxiosResponse<ICartResponse>> => {
  const response = $axios.get('/cart');
  return response;
};
export const addToCart = (form:IAddCart):Promise<AxiosResponse> => {
  const response = $axios.post('/cart', form);
  return response;
};

export const updateToCart = (form:IUpdateCart):Promise<AxiosResponse> => {
  const response = $axios.put('/cart/amount', form);
  return response;
};

export const deleteFromCart = (cartId:string):Promise<AxiosResponse> => {
  const response = $axios.delete('/cart', { data: { cartId } });
  return response;
};

export const summaryPrice = (cartIds:string[]):Promise<AxiosResponse<ISummaryCart>> => {
  const response = $axios.post('/cart/summary-price', { cartIds });
  return response;
};
