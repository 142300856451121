import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICart, ISummaryCart } from '../../interface/Cart';

// Define a type for the slice state
interface CartState {
  carts: ICart[];
  summaryCart?: ISummaryCart;
  isLoading: boolean
}

// Define the initial state using that type
const initialState: CartState = {
  carts: [],
  summaryCart: undefined,
  isLoading: false,
};

export const cartSlice = createSlice({
  name: 'cart',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCart(state, action: PayloadAction<ICart[]>) {
      return {
        ...state,
        carts: action.payload,
      };
    },
    setSummaryCart(state, action:PayloadAction<ISummaryCart|undefined>) {
      return {
        ...state,
        summaryCart: action.payload,
      };
    },
    setLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
  },
});

export const {
  setCart, setSummaryCart, setLoading,
} = cartSlice.actions;

export default cartSlice.reducer;
