import { RcFile } from 'antd/lib/upload';
import { AxiosResponse } from 'axios';
import { IMedia } from '../interface/Media';
import $axios from '../plugin/axios';

export const uploadImage = (file:RcFile): Promise<AxiosResponse<IMedia>> => {
  const formData = new FormData();
  formData.append('file', file);
  const response = $axios.post('/image/upload', formData);
  return response;
};

export const uploadVideo = (file:RcFile):Promise<AxiosResponse<IMedia>> => {
  const formData = new FormData();
  formData.append('file', file);
  const response = $axios.post('/video/upload', formData);
  return response;
};
