/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
import {
  Button,
  Col, Form, Input, Rate, Row, Space, Upload,
} from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { useState } from 'react';
import productHook from '../../hooks/product-hook';
import { IOrderProduct } from '../../interface/Order';
import { ICreateProcutReview } from '../../interface/ProductReview';
import { uploadImage, uploadVideo } from '../../services/media-service';
import './index.scss';

type IPanelReviewProps = {
    orderProduct: IOrderProduct;
    orderId:string;
    onCancel: () => void
    onReviewed:() =>void
}
const PanelReview = ({
  orderProduct, orderId, onCancel, onReviewed,
}:IPanelReviewProps) :JSX.Element => {
  const { reviewProduct, setLoading } = productHook();

  const [fileList, _fileList] = useState<UploadFile<any>[]>([]);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const { images } = values;
      const imagesUrl = [];

      if (fileList && fileList.length > 0) {
        for (const i in images.fileList) {
          const responseUploadImage = await uploadImage(images.fileList[i].originFileObj);
          imagesUrl.push(responseUploadImage.data._id);
        }
      }

      const formData:ICreateProcutReview = {
        orderId,
        productId: orderProduct.product._id,
        images: imagesUrl,
        comment: values.comment,
        rate: values.rate,
      };

      await reviewProduct(formData);
      onReviewed();
      onCancel();
    } catch (err) {
      alert('รีวิวล้มเหลว !');
    } finally {
      setLoading(false);
    }
  };

  const onImageChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    if (!info?.fileList === undefined) return;
    _fileList(info.fileList);
  };

  return (
    <Form className="panel__review" onFinish={onFinish}>
      <Row gutter={[24, 24]}>
        <Col span={24} className="header">
          <Row>
            <Col span={12} className="about_product">
              <Space direction="vertical">
                <div className="name">{orderProduct.product.product}</div>
                <div className="description">
                  {orderProduct.product.description}
                </div>
              </Space>
            </Col>
            <Col span={12} className="rate">
              <Space size={[24, 24]} align="start">
                <div style={{ paddingTop: 6 }}>ให้คะแนน</div>
                <Form.Item name="rate" rules={[{ required: true, message: 'กรุณาให้คะแนน' }]}>
                  <Rate style={{ color: '#535252' }} />
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Form.Item name="comment" rules={[{ required: true, message: 'กรุณากรออกคอมเมนต์' }]}>
            <Input.TextArea rows={5} placeholder="คอมเมนต์" style={{ background: 'rgba(229, 229, 229, 0.31)' }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="images"
          >
            <Upload multiple accept="image/*" beforeUpload={() => false} onChange={onImageChange} fileList={fileList}>
              <Button size="small" className="media_btn img">เพิ่มรูปภาพ</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={24}>
          <div style={{ textAlign: 'right' }}>
            <Button className="control_btn cancel" onClick={onCancel}>ยกเลิก</Button>
            <Button className="control_btn confirm" htmlType="submit">ยืนยัน</Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default PanelReview;
