/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import {
  Button, Col, Divider, InputNumber, Rate, Row, Select, Space, Tabs,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import StarIcon from '../../common/icons/star_icon';
import BreadcrumbComponent, { IRouteProps } from '../../components/Breadcrumb';
import ProductReviews from '../../components/ProductReviews';
import CartHook from '../../hooks/cart-hook';
import productHook from '../../hooks/product-hook';
import { IAddCart, ICartProduct } from '../../interface/Cart';
import { IMedia } from '../../interface/Media';
import { IProductDetail, IProductVarDetail, IProductVariantSaleInfo } from '../../interface/Product';
import './index.scss';

const { Option } = Select;
const { TabPane } = Tabs;

type IActiveMedia = {
  isVideo?: boolean
} & IMedia

const ProductDetail: FC = () => {
  const router = useHistory();
  const { productId }: { productId: string } = useParams();
  const [productByID, setProductByID] = useState<IProductDetail>();
  const [mainImage, setMainImage] = useState<IMedia[]>();
  const [activeImage, setActiveImage] = useState<IActiveMedia>();
  const [video, setVideo] = useState<IMedia>();
  const [feature1, setFeature1] = useState<IProductVarDetail>();
  const [feature2, setFeature2] = useState<IProductVarDetail>();
  const [var1, _var1] = useState<number>();
  const [var2, _var2] = useState<number>();
  const [selectedInfo, setSelectedInfo] = useState<IProductVariantSaleInfo>();
  const [productInCart, _productInCart] = useState<ICartProduct>();
  const [qty, _qty] = useState(0);
  const routes: IRouteProps[] = [];

  if (productId) {
    routes.push({ path: `/category/${productByID?.categories[0]?._id}`, title: productByID?.categories[0]?.name || '' });
    routes.push({ path: `/product/${productId}`, title: productByID?.product || '' });
  }

  const [page, _page] = useState(1);
  const size = 10;

  const {
    findProduct, fetchProductReview, productReviews, totalReviews, fetchProductAddReview,
  } = productHook();
  const {
    onAddToCart, checkInCart, fetchCart, carts,
  } = CartHook();

  const fetchData = async () => {
    if (productId) {
      const data = await findProduct(productId);
      if (data) {
        const imageInfo = data.variantSaleInfo.map((q) => q.image);
        const allImage = [...data.images, ...imageInfo];
        await fetchProductReview(data._id, page, size);
        setMainImage(allImage);
        setActiveImage(allImage[0]);
        setVideo(data.video);
        setProductByID(data);
        setFeature1(data.var1);
        setFeature2(data.var2);
        if (data.variantSaleInfo.length > 0) {
          const sortVariant = [...data.variantSaleInfo].sort((a, b) => a.price - b.price);
          let var2x;
          if (sortVariant[0].var2 === null) {
            var2x = undefined;
          } else {
            var2x = sortVariant[0].var2;
          }
          _var1(sortVariant[0].var1);
          _var2(var2x);
        }
      } else {
        router.goBack();
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [productId]);

  useEffect(() => {
    const checkNullVar2 = var2 === undefined ? null : var2;
    const find = productByID?.variantSaleInfo.find((q) => q.var1 === var1 && q.var2 === checkNullVar2);
    if (find) {
      setSelectedInfo(find);
    } else {
      setSelectedInfo(undefined);
    }
  }, [var1, var2]);

  useEffect(() => {
    if (selectedInfo) {
      const inCart = checkInCart(productId, selectedInfo?._id);
      _productInCart(inCart);
      _qty(inCart?.amount || 0);
    } else {
      _productInCart(undefined);
    }
  }, [selectedInfo, carts]);

  const minimumOrder = selectedInfo?.minimumOrder || 0;
  const stockInfo = selectedInfo?.stock || 0;
  const canAddToCart = qty >= minimumOrder && qty > 0 && stockInfo > 0 && qty <= stockInfo;

  const onAddToCartClick = async () => {
    try {
      if (selectedInfo) {
        await onAddToCart(productId, selectedInfo?._id, qty);
        await fetchCart();
      }
    } catch (err) {
      alert('เพิ่มไปยังรถเข็นไม่สำเร็จ !');
    }
  };

  return (
    <div className="product_detail_page">

      <BreadcrumbComponent routes={routes} />

      <div className="detail">
        <Row gutter={[100, 100]}>
          <Col span={24} xs={24} md={11}>
            <Row gutter={[0, 28]}>
              <Col span={24}>
                {activeImage?.isVideo
                  ? (
                    <div className="main__image">
                      <video autoPlay muted loop id="myVideo">
                        <source src={activeImage?.url} type="video/mp4" />
                      </video>
                    </div>
                  )
                  : <div className="main__image" style={{ background: `url(${activeImage?.url})` }} />}
              </Col>
              <Col span={24}>
                <Space size={[18, 0]} wrap={false} style={{ width: '100%', overflowX: 'scroll' }}>
                  {mainImage ? mainImage.map((img: IMedia, index: number) => (
                    <div
                      onClick={() => {
                        setActiveImage(img);
                      }}
                      className="sub__image"
                      style={{ background: `url(${img?.url})` }}
                      key={`${String(index)}__subimg`}
                    />
                  )) : null}
                  {video ? (
                    <div onClick={() => {
                      setActiveImage({ ...video, isVideo: true });
                    }}
                    >
                      <video src={video.url} width={74} height={74} style={{ cursor: 'pointer' }}>
                        <track kind="captions" />
                      </video>
                    </div>
                  ) : null}
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={24} xs={24} md={13}>
            <Row gutter={[0, 35]}>
              <Col span={24}>
                <div className="product_name">
                  {productByID?.product}
                </div>
                <Space className="product_reviews">
                  <Rate className="Rate" allowHalf value={productByID?.rate || 0} style={{ fontSize: 18 }} />
                  <div>
                    {totalReviews}
                    {' '}
                    รีวิว
                  </div>
                </Space>
              </Col>
              <Col span={24}>
                <div className="product_price">
                  <div>
                    {selectedInfo ? selectedInfo.price : productByID?.variantSaleInfo[0].price}
                    {' '}
                    บาท
                    /
                  </div>
                  <div>
                    {selectedInfo ? selectedInfo.unit.name : productByID?.variantSaleInfo[0].unit.name}
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <Space size={39}>
                  <div className="attribute__product">
                    <div>
                      {feature1?.name}
                    </div>
                    <Select value={var1} onChange={(e) => _var1(e)}>
                      {
                        feature1?.list.map((m, index) => <Option key={`f1__op__${String(index)}`} value={m.index}>{m.name}</Option>)
                      }
                    </Select>
                  </div>

                  <div className="attribute__product">
                    <div>
                      {feature2?.name}
                    </div>
                    {feature2
                      ? (
                        <Select value={var2} onChange={(e) => _var2(e)}>
                          {feature2.list.map((m, index) => <Option key={`f2__op__${String(index)}`} value={m.index}>{m.name}</Option>)}
                        </Select>
                      ) : null}
                  </div>
                </Space>
              </Col>
              <Col span={24}>
                คลัง :
                {' '}
                {selectedInfo?.stock || 0}
              </Col>
              <Col span={24} className="qty__product">
                จำนวน
                <div>
                  <InputNumber min={0} style={{ width: '200px' }} value={qty} onChange={(e) => _qty(e)} />
                  <div>
                    สั่งซื้อขั้นต่ำจำนวน
                    {' '}
                    {minimumOrder || 0}
                    {' '}
                    {selectedInfo?.unit.name || ''}
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <Space size={32}>
                  <Button
                    size="large"
                    onClick={onAddToCartClick}
                    className={`add_to_cart_btn ${!canAddToCart ? 'disabled_btn' : ''}`}
                    disabled={!canAddToCart}
                  >
                    เพิ่มไปยังรถเข็น
                  </Button>
                  {qty < minimumOrder ? (
                    <div className="error_note">
                      *ต้องสั่งซื้อสินค้ามากกว่าหรือเท่ากับ
                      {' '}
                      {minimumOrder || 0}
                    </div>
                  ) : null}
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
        <Tabs defaultActiveKey="1" centered className="desciption__tabs">
          <TabPane tab="รายละเอียดสินค้า" key="1">
            <div>
              <div className="about_product">
                {productByID?.description}
              </div>
            </div>
          </TabPane>
          <TabPane tab={`รีวิว (${totalReviews})`} key="2">
            {productReviews.length > 0 ? (
              <>
                <div className="product__review__section">
                  {productReviews.map((review, index) => <ProductReviews key={`${String(index)}__reviews`} review={review} />)}
                </div>
                {page * size >= totalReviews ? null
                  : (
                    <div className="more__btn">
                      <Divider>
                        <Button onClick={() => {
                          const newPage = page + 1;
                          fetchProductAddReview(productByID?._id || '', newPage, size);
                          _page(newPage);
                        }}
                        >
                          ดูเพิ่มเติม
                        </Button>
                      </Divider>
                    </div>
                  )}
              </>
            ) : <div>ยังไม่มีการรีวิว...</div>}
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default ProductDetail;
