/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-useless-catch */
import { useAppDispatch, useAppSelector } from '../reducers/store';
import * as CartService from '../services/cart-service';
import * as CartSlice from '../reducers/state/cart-slice';
import { IAddCart, IUpdateCart } from '../interface/Cart';

const CartHook = () => {
  const { carts, summaryCart, isLoading } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();

  const cartsItem = carts.reduce((prev, cur) => prev + cur.list.length, 0);

  const setLoading = (flag:boolean) => {
    dispatch(CartSlice.setLoading(flag));
  };

  const fetchCart = async () => {
    try {
      setLoading(true);
      const { data } = await CartService.getCart();
      dispatch(CartSlice.setCart(data.carts));
    } catch (err) {
      dispatch(CartSlice.setCart([]));
    } finally {
      setLoading(false);
    }
  };

  const addToCart = async (form:IAddCart) => {
    try {
      setLoading(true);
      await CartService.addToCart(form);
      await fetchCart();
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const updateToCart = async (form:IUpdateCart) => {
    try {
      setLoading(true);
      await CartService.updateToCart(form);
      await fetchCart();
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const deleteInCart = async (cartId:string) => {
    try {
      setLoading(true);
      await CartService.deleteFromCart(cartId);
      await fetchCart();
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const fetchSummaryCart = async (cartIds: string[]) => {
    setLoading(true);
    const { data } = await CartService.summaryPrice(cartIds);
    dispatch(CartSlice.setSummaryCart(data));
    setLoading(false);
  };

  const checkInCart = (productId:string, variantSaleInfoId:string) => {
    for (const cart of carts) {
      const { list } = cart;
      const findItem = list.find((q) => q.product._id === productId && q.variantSaleInfo._id === variantSaleInfoId);
      if (findItem) {
        return findItem;
      }
    }
    return undefined;
  };

  const onAddToCart = async (productId:string, variantSaleInfoId:string, amount:number) => {
    try {
      const findItem = checkInCart(productId, variantSaleInfoId);
      if (!findItem) {
        console.log('add');
        const formData:IAddCart = {
          productId,
          variantSaleInfoId,
          amount,
        };
        await addToCart(formData);
      } else {
        console.log('update');
        const formData:IUpdateCart = {
          cartId: findItem._id,
          amount,
        };
        await updateToCart(formData);
      }
      await fetchCart();
    } catch (err) {
      throw err;
    }
  };

  const clearSummaryCart = () => {
    dispatch(CartSlice.setSummaryCart(undefined));
  };

  return {
    fetchCart,
    addToCart,
    updateToCart,
    deleteInCart,
    fetchSummaryCart,
    clearSummaryCart,
    onAddToCart,
    checkInCart,
    carts,
    cartsItem,
    summaryCart,
    isLoading,
  };
};

export default CartHook;
