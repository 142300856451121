/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
import {
  Button, Col, DatePicker, InputNumber, Radio, Row, Space, TimePicker, Upload,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { formatMoney } from '../../common/hepler';
import orderHook from '../../hooks/order-hook';
import PaymentInfoHook from '../../hooks/payment-info-hook';
import { IOrderDetail, IOrderPaymentRequest } from '../../interface/Order';
import { uploadImage } from '../../services/media-service';
import './index.scss';

type IStepOrderInform = {
  onNextStep: () => void
  onBackStep: ()=> void
  orderByID?: IOrderDetail
}

const StepOrderInform:FC<IStepOrderInform> = ({ onNextStep, onBackStep, orderByID }:IStepOrderInform) => {
  const { handleSubmit, control } = useForm<IOrderPaymentRequest>();
  const { submitPayment, setLoading } = orderHook();

  const [slip, _slip] = useState<UploadFile>();
  const [tDate, _tDate] = useState<moment.Moment | null>();
  const [tTime, _tTime] = useState<moment.Moment | null>();

  const handleOnSubmit = async (data:IOrderPaymentRequest) => {
    if (orderByID?._id && slip?.originFileObj && tDate && tTime) {
      try {
        setLoading(true);
        const uploadSlip = await uploadImage(slip?.originFileObj);
        const slipId = uploadSlip.data._id;
        const transferDate = tDate.format('DDMMYYYY');
        const transferTime = tTime.format('HHmm');
        const form = {
          ...data, orderId: orderByID._id, slipId, transferDate, transferTime,
        };
        await submitPayment(form);
        onNextStep();
      } catch (err) {
        alert('ไม่สำเร็จ !');
      } finally {
        setLoading(false);
      }
    }
  };

  const { fetchPaymentType, paymentType } = PaymentInfoHook();

  const fetchData = () => {
    fetchPaymentType();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="step__Inform">
      <Row justify="space-between" gutter={[43, 0]}>
        <Col span={24} xs={24} md={14}>
          <Row gutter={[0, 43]} className="inform_detail">
            <Col span={24}>
              <div className="card__inform card__shipping">
                <div>
                  1. รายละเอียดการจัดส่ง
                </div>
                <div>
                  <div>
                    {orderByID?.shippingName}
                  </div>
                  <div>
                    เบอร์ติดต่อขนส่ง:
                    {' '}
                    {orderByID?.shippingPhoneNumber}
                  </div>
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className="card__inform card__summary">
                <div>
                  2. ยอดรวม
                </div>
                <div>
                  <div>
                    <div>สินค้า</div>
                    <div>
                      {formatMoney(orderByID?.orders.reduce((prev, cur) => prev + cur.productTotalPrice, 0), 0)}
                      {' '}
                      บาท
                    </div>
                  </div>
                  <div>
                    <div>ค่าขนส่ง</div>
                    <div>
                      {formatMoney(orderByID?.shippingFee, 0)}
                      {' '}
                      บาท
                    </div>
                  </div>
                  <div className="total">
                    <div>รวม:</div>
                    <div>
                      {formatMoney(orderByID?.totalPrice, 0)}
                      {' '}
                      บาท
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className="card__inform card__payment">
                <div>
                  3. รายละเอียดการชำระเงิน
                </div>
                <div>
                  <div>
                    {orderByID?.paymentInfo.brandName}
                  </div>
                  <div>
                    เลขที่บัญชี :
                    {' '}
                    {orderByID?.paymentInfo.accountNumber}
                  </div>
                  <div>
                    สาขา :
                    {' '}
                    {orderByID?.paymentInfo.branch}
                  </div>
                  <div>
                    ชื่อบัญชี :
                    {' '}
                    {orderByID?.paymentInfo.accountName}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24} xs={24} md={10} style={{ paddingTop: '25px' }}>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="inform_payment">
              <h1>
                ชำระเงิน
              </h1>
              <Row gutter={[37, 22]}>
                <Col span="10">
                  หลักฐานการชำระเงิน:
                </Col>
                <Col span="14" className="upload__section">
                  <Controller
                    control={control}
                    name="slipId"
                    render={({ field: { onChange }, fieldState: { error } }) => (
                      <>
                        <Upload
                          maxCount={1}
                          multiple={false}
                          onChange={(e) => {
                            try {
                              onChange(e.fileList[0].uid);
                              _slip(e.fileList[0]);
                            } catch (err) {
                              onChange(undefined);
                              _slip(undefined);
                            }
                          }}
                          beforeUpload={undefined}
                          accept="image/*"
                        >
                          <Button>เลื่อกไฟล์</Button>
                        </Upload>
                        {error ? <div className="error__input__text">{error.message}</div> : null}
                      </>
                    )}
                    rules={{ required: 'กรุณาเลือกรูปภาพ' }}
                  />
                </Col>
                <Col span="10">
                  วิธีการชำระเงิน:
                </Col>
                <Col span="14">
                  <Controller
                    control={control}
                    name="paymentTypeId"
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <Radio.Group size="small" onChange={onChange} value={value}>
                          <Space direction="vertical">
                            {paymentType.map((val, index) => <Radio key={`${String(index)}__radio`} value={val._id}>{val.name}</Radio>)}
                          </Space>
                        </Radio.Group>
                        {error ? <div className="error__input__text">{error.message}</div> : null}
                      </>
                    )}
                    rules={{ required: 'กรุณากรอกข้อมูล' }}
                  />
                </Col>
                <Col span="10">
                  จำนวนเงิน:
                </Col>
                <Col span="14">
                  <Controller
                    control={control}
                    name="transferAmount"
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <InputNumber
                          size="small"
                          style={{ width: '100%' }}
                          onChange={onChange}
                          value={value}
                        />
                        {error ? <div className="error__input__text">{error.message}</div> : null}
                      </>
                    )}
                    rules={{ required: 'กรุณากรอกข้อมูล' }}
                  />
                </Col>
                <Col span="10">
                  วันที่ชำระเงิน:
                </Col>
                <Col span="14">
                  <Controller
                    control={control}
                    name="transferDate"
                    render={({ field: { onChange }, fieldState: { error } }) => (
                      <>
                        <DatePicker
                          size="small"
                          style={{ width: '100%' }}
                          placeholder=""
                          onChange={(e) => {
                            onChange(e);
                            _tDate(e);
                          }}
                        />
                        {error ? <div className="error__input__text">{error.message}</div> : null}
                      </>
                    )}
                    rules={{ required: 'กรุณากรอกข้อมูล' }}
                  />
                </Col>
                <Col span="10">
                  เวลา :
                </Col>
                <Col span="14">
                  <Controller
                    control={control}
                    name="transferTime"
                    render={({ field: { onChange }, fieldState: { error } }) => (
                      <>
                        <TimePicker
                          placeholder=""
                          size="small"
                          format="HH:mm"
                          style={{ width: '100%' }}
                          onChange={(e) => {
                            onChange(e);
                            _tTime(e);
                          }}
                        />
                        {error ? <div className="error__input__text">{error.message}</div> : null}
                      </>
                    )}
                    rules={{ required: 'กรุณากรอกข้อมูล' }}
                  />
                </Col>
              </Row>
            </div>
            <div className="btn_control">
              <div>
                <Button htmlType="submit">ยืนยันการชำระเงิน</Button>
              </div>
              <div>
                <Button onClick={onBackStep}>กลับ</Button>
              </div>
            </div>
          </form>
        </Col>
      </Row>
    </div>
  );
};

export default StepOrderInform;
