const LoadingApp = () => (
  <div className="loading-app-screen">
    <div className="lds-ellipsis">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default LoadingApp;
