/* eslint-disable no-useless-catch */
import { useAppDispatch, useAppSelector } from '../reducers/store';
import * as orderService from '../services/order-service';
import * as orderSlice from '../reducers/state/order-slice';
import { IOrderCheckoutRequest, IOrderPaymentRequest } from '../interface/Order';

const orderHook = () => {
  const { orders, isLoading, total } = useAppSelector((state) => state.order);
  const dispatch = useAppDispatch();

  const fetchOrder = async (page = 1, size = 10) => {
    try {
      dispatch(orderSlice.setLoading(true));
      const { data } = await orderService.getAll(page, size);
      if (page <= 1) {
        dispatch(orderSlice.setOrder(data));
      } else {
        dispatch(orderSlice.addOrder(data));
      }
    } catch (err) {
      // throw err;
    } finally {
      dispatch(orderSlice.setLoading(false));
    }
  };

  const fetchOrderByStatus = async (orderStatus:string, page = 1, size = 10) => {
    try {
      dispatch(orderSlice.setLoading(true));
      const { data } = await orderService.getAllStatus(orderStatus, page, size);
      if (page <= 1) {
        dispatch(orderSlice.setOrder(data));
      } else {
        dispatch(orderSlice.addOrder(data));
      }
    } catch (err) {
      // throw err;
    } finally {
      dispatch(orderSlice.setLoading(false));
    }
  };

  const fetchOrderDetail = async (orderId :string) => {
    try {
      dispatch(orderSlice.setLoading(true));
      const { data } = await orderService.getOne(orderId);
      return data;
    } catch (err) {
      return undefined;
    } finally {
      dispatch(orderSlice.setLoading(false));
    }
  };

  const submitPayment = async (form:IOrderPaymentRequest) => {
    dispatch(orderSlice.setLoading(false));
    await orderService.submitPayment(form);
    dispatch(orderSlice.setLoading(false));
  };

  const fetchInvoice = async (page = 1, size = 10) => {
    try {
      dispatch(orderSlice.setLoading(true));
      const { data } = await orderService.getAllInvoice(page, size);
      if (page <= 1) {
        dispatch(orderSlice.setOrder(data));
      } else {
        dispatch(orderSlice.addOrder(data));
      }
    } catch (err) {
      // throw err;
    } finally {
      dispatch(orderSlice.setLoading(false));
    }
  };

  const fetchInvoiceByStatus = async (orderStatus:string, page = 1, size = 10) => {
    try {
      dispatch(orderSlice.setLoading(true));
      const { data } = await orderService.getAllInvoiceStatus(orderStatus, page, size);
      if (page <= 1) {
        dispatch(orderSlice.setOrder(data));
      } else {
        dispatch(orderSlice.addOrder(data));
      }
    } catch (err) {
      // throw err;
    } finally {
      dispatch(orderSlice.setLoading(false));
    }
  };

  const setLoading = (loading:boolean) => dispatch(orderSlice.setLoading(loading));

  const orderCheckOut = async (form:IOrderCheckoutRequest) => {
    try {
      setLoading(true);
      await orderService.retailerCheckout(form);
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const confirmReceived = async (orderId : string) => {
    try {
      setLoading(true);
      await orderService.retailerConfirmReceived(orderId);
      // dispatch(orderSlice.updateReceivedOrder(orderId));
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    fetchInvoice,
    fetchInvoiceByStatus,
    fetchOrder,
    fetchOrderByStatus,
    fetchOrderDetail,
    confirmReceived,
    submitPayment,
    setLoading,
    orderCheckOut,
    orders,
    isLoading,
    total,
  };
};

export default orderHook;
