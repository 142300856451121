import { IUserLogin } from '../interface/User';
import * as authSlice from '../reducers/state/auth-slice';
import * as authService from '../services/auth-service';
import { useAppDispatch, useAppSelector } from '../reducers/store';
import { ICreateRetailer, ICreateWholesaler } from '../interface/RegisterForm';

const AuthHook = () => {
  const dispatch = useAppDispatch();
  const {
    isLoading, me, accessToken, isModalLoginVisible, isModalRegisterVisible, registerEmail,
  } = useAppSelector((state) => state.auth);

  const setVisibleModalLogin = (flag: boolean) => {
    dispatch(authSlice.setVisibleModalLogin(flag));
  };

  const setVisibleModalRegister = (flag: boolean) => {
    dispatch(authSlice.setVisibleModalRegister(flag));
  };

  const login = async (form: IUserLogin) => {
    dispatch(authSlice.setLoading(true));
    const { data } = await authService.loginService(form);
    dispatch(authSlice.setToken(data));
    localStorage.setItem('accessToken', data.accessToken);
    localStorage.setItem('refreshToken', data.refreshToken);
    dispatch(authSlice.setLoading(false));
  };

  const logout = async () => {
    dispatch(authSlice.setLoading(true));
    await authService.logoutService();
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    dispatch(authSlice.setLoading(false));
  };

  const setRegisterEmail = (email: string) => {
    dispatch(authSlice.setRegisterEmail(email));
  };

  const registerWholesaler = async (form: ICreateWholesaler) => {
    try {
      dispatch(authSlice.setLoading(true));
      await authService.registerWholesaler(form);
      return true;
    } catch (err) {
      return false;
    } finally {
      dispatch(authSlice.setLoading(false));
    }
  };

  const registerRetailer = async (form: ICreateRetailer) => {
    try {
      dispatch(authSlice.setLoading(true));
      await authService.registerRetailer(form);
      return true;
    } catch (err) {
      return false;
    } finally {
      dispatch(authSlice.setLoading(false));
    }
  };

  const clearToken = () => {
    dispatch(authSlice.setToken({ accessToken: undefined, refreshToken: undefined }));
  };

  const clearLoading = () => {
    dispatch(authSlice.setLoading(false));
  };

  return {
    clearToken,
    clearLoading,
    isLoading,
    me,
    accessToken,
    isModalLoginVisible,
    isModalRegisterVisible,
    registerEmail,
    setVisibleModalLogin,
    setVisibleModalRegister,
    setRegisterEmail,
    login,
    logout,
    registerWholesaler,
    registerRetailer,
  };
};

export default AuthHook;
