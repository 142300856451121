/* eslint-disable no-control-regex */
/* eslint-disable no-throw-literal */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-alert */
/* eslint-disable max-len */
import {
  Button, Col, Form, Input, Modal, Row, Space,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import EmailIcon from '../../common/icons/email_icon';
import LogoIcon from '../../common/icons/logo_icon';
import AuthHook from '../../hooks/auth-hook';
import { verifyRetailerEmail, verifyWholesalerEmail } from '../../services/auth-service';
import './index.scss';

const ModalRegister:FC = () => {
  const router = useHistory();
  const {
    isModalRegisterVisible, setVisibleModalRegister, setVisibleModalLogin, setRegisterEmail,
  } = AuthHook();

  const [canUseEmail, _canUseEmail] = useState<boolean | undefined>();

  const [form] = Form.useForm();
  const handleCancel = () => {
    setVisibleModalRegister(false);
    form.resetFields();
  };

  const onFinish = async (values:{email:string}) => {
    // setRegisterEmail(values.email);
    // router.push(`/register/form/${registerType}`);
    // handleCancel();
  };

  const onFinishFailed = async () => {
    console.log('failed');
  };

  const loginClick = () => {
    handleCancel();
    setTimeout(() => {
      setVisibleModalLogin(true);
    }, 300);
  };

  const onFormClick = async (type:string) => {
    try {
      await form.validateFields();
      const email = form.getFieldValue('email');
      let available;
      if (type === 'wholesaler') {
        const { data } = await verifyWholesalerEmail(email);
        available = data.available;
      } else if (type === 'retailer') {
        const { data } = await verifyRetailerEmail(email);
        available = data.available;
      }

      if (available) {
        _canUseEmail(true);
        setRegisterEmail(email);
        router.push(`/register/form/${type}`);
        handleCancel();
      } else {
        _canUseEmail(false);
        await form.validateFields();
      }
    } catch (err) {
      _canUseEmail(false);
      await form.validateFields();
    }
  };

  return (
    <Modal title={null} footer={null} visible={isModalRegisterVisible} onCancel={handleCancel} closable={false}>
      <div className="register_modal">
        <LogoIcon />
        <h2>
          สมัครใหม่
        </h2>
        <p>
          กด &nbsp;&quot;สมัครเป็นลูกค้า&quot;&nbsp; เพื่อซื้อสินค้ากับ YKC
        </p>
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            name="email"
            rules={[
              {
                validator: (rule, value, callback) => {
                  // eslint-disable-next-line no-useless-escape
                  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
                  const pattern = regex.test(value);
                  if (canUseEmail === false) {
                    callback('Email นี้ไม่สามารถใช้ได้ !');
                  } else if (canUseEmail === undefined && value === '') {
                    callback('กรุณากรอก Email !');
                  } else if (canUseEmail === undefined && !pattern) {
                    callback('กรุณากรอก Email ให้ถูกต้อง !');
                  } else {
                    callback(undefined);
                  }
                },
              },
            ]}
          >
            <Input type="email" size="large" placeholder="Email" className="input_login" prefix={<EmailIcon />} onChange={() => _canUseEmail(undefined)} />
          </Form.Item>

          <Row justify="space-between">
            <Col flex="49%">
              <Button
                className="register_btn"
                onClick={() => onFormClick('wholesaler')}
              >
                สมัครเป็นร้านค้า
              </Button>

            </Col>
            <Col flex="49%">
              <Button
                className="register_btn"
                onClick={() => onFormClick('retailer')}
              >
                สมัครเป็นลูกค้า
              </Button>

            </Col>
          </Row>
          <div className="login_btn">
            <a onClick={loginClick}>เข้าสู่ระบบ</a>
          </div>
        </Form>
      </div>

    </Modal>
  );
};

export default ModalRegister;
