/* eslint-disable max-len */
import { FC, useEffect, useState } from 'react';
import { Button, Select } from 'antd';
import './index.scss';
import { useHistory } from 'react-router';
import TableOrder from '../../components/TableOrder';
import orderHook from '../../hooks/order-hook';
import { OrderStatus } from '../../components/order_status';
import { ITextOrderStatus } from '../../interface/Order';
import { useQuery } from '../../common/hepler';

const { Option } = Select;

const Order:FC = () => {
  const query = useQuery();
  const orderStatus = query.get('orderStatus') || '';
  const router = useHistory();

  // const [queryStatus, _queryStatus] = useState<string>();
  const [page, _page] = useState<number>(1);
  const {
    fetchOrderByStatus, fetchOrder, orders, total,
  } = orderHook();

  const fetchData = async () => {
    if (orderStatus === null || orderStatus === undefined) {
      await fetchOrder(page, 10);
    } else {
      await fetchOrderByStatus(orderStatus, page, 10);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, orderStatus]);

  const loadmore = () => {
    const nextPage = page + 1;
    _page(nextPage);
  };

  const onQueryChange = async (e:any) => {
    // _queryStatus(e);
    if (e) {
      router.push(`/order?orderStatus=${e}`);
    } else {
      router.push('/order');
    }

    _page(1);
  };

  const onTableUpdate = async () => {
    if (page > 1) {
      _page(1);
    } else {
      await fetchData();
    }
  };

  return (
    <div className="order__page">
      <div>
        <h1>รายการสั่งซื้อ</h1>
        <div style={{
          display: 'flex', width: '100%', justifyContent: 'end', marginBottom: '25px',
        }}
        >
          <Select placeholder="เลือกสถานะ" style={{ width: 360 }} size="large" value={orderStatus} onChange={onQueryChange}>
            <Option value="">ทั้งหมด</Option>
            {OrderStatus.map((os:ITextOrderStatus, index:number) => <Option value={os.orderStatus} key={String(index)}>{os.label}</Option>)}
          </Select>
        </div>
        <TableOrder
          rows={orders}
          onTableUpdate={onTableUpdate}
        />
        {total > orders.length ? (
          <div className="text-center">
            <Button onClick={loadmore} className="load__more__btn">Load more...</Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Order;
