/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import {
  Button, Col, Popconfirm, Radio, Row, Space,
} from 'antd';
import './index.scss';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import TableConfirm from '../../components/TableConfirm';
import CartHook from '../../hooks/cart-hook';
import { formatMoney } from '../../common/hepler';
import AddressHook from '../../hooks/address-hook';
import orderHook from '../../hooks/order-hook';
import { IOrderCheckoutRequest } from '../../interface/Order';
import ModalActionAddress from '../../components/ModalActionAddress';

const CartCheckout = () :JSX.Element => {
  const router = useHistory();
  const { summaryCart, clearSummaryCart, fetchCart } = CartHook();
  const { fetchAddress, deleteAddress, address } = AddressHook();
  const { orderCheckOut } = orderHook();

  const [modalVisible, _modalVisible] = useState(false);
  const [editId, _editId] = useState<string|undefined>();

  const fetchData = async () => {
    await fetchAddress();
  };

  useEffect(() => {
    if (summaryCart === undefined) {
      router.push('/cart');
    }
    fetchData();
  }, []);

  const [addressId, _addressId] = useState<string>();

  const onCheckout = async () => {
    if (address && summaryCart) {
      try {
        const formData:IOrderCheckoutRequest = {
          addressId: addressId || '',
          orderToken: summaryCart?.orderToken || '',
        };
        await orderCheckOut(formData);
        clearSummaryCart();
        await fetchCart();
        router.push('/order');
      } catch (err) {
        alert('checkout ไม่สำเร็จ');
      }
    }
  };

  const confirmDeleteAddress = async (delId:string) => {
    try {
      _addressId(undefined);
      await deleteAddress(delId);
      await fetchAddress();
    } catch (err) {
      alert('ลบที่อยู่ไม่สำเร็จ !');
    }
  };

  return (
    <>
      <div className="CartConfirm">
        <div className="header">
          รายการสั่งซื้อ
        </div>
        <Row className="Body" gutter={[24, 24]}>
          <Col span={24} xs={24} md={12}>
            <div className="Store">
              {summaryCart?.carts.map((ca, index) => <TableConfirm cart={ca} key={String(index)} />)}
            </div>
          </Col>
          <Col span={24} xs={24} md={12}>
            <div className="NoticationShipping">
              <div className="header">
                ที่อยู่จัดส่ง
              </div>
              <div className="ListAddress">
                <Row align="stretch">
                  <Col span={20}>
                    <Radio.Group value={addressId} onChange={(e) => _addressId(e.target.value)}>
                      <Space direction="vertical" style={{ width: '100%' }}>
                        {address.map((radio, index) => (
                          <Radio value={radio._id} key={`${String(index)}__radio`}>
                            <div className="HeadAddress">{radio.fullName}</div>
                            <div className="DetailAddress">
                              Tel:
                              {' '}
                              {radio.phoneNumber}
                              <br />
                              <br />
                              {radio?.address}
                              {' '}
                              {radio?.province?.name}
                              {' '}
                              {radio?.country?.name}
                              {' '}
                              {radio?.postalCode}
                            </div>
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  </Col>
                  <Col span={4}>
                    <Space direction="vertical" className="space_delete">
                      {address.map((action, index) => (
                        <div key={`action_${String(index)}`}>
                          <EditOutlined
                            style={{
                              fontSize: 18,
                              cursor: 'pointer',
                              marginRight: 12,
                            }}
                            onClick={() => {
                              _editId(action._id);
                              _modalVisible(true);
                            }}
                          />
                          <Popconfirm placement="topLeft" title="ต้องการลบที่อยู่นี้ ?" onConfirm={() => confirmDeleteAddress(action._id)} okText="ตกลง" cancelText="ยกเลิก">
                            <DeleteOutlined
                              style={{
                                fontSize: 18,
                                cursor: 'pointer',
                              }}
                            />
                          </Popconfirm>
                        </div>
                      ))}
                    </Space>
                  </Col>
                </Row>

              </div>
              <Button className="NewAddress" aria-hidden onClick={() => { _modalVisible(true); }}>
                + เพิ่มที่อยู่
              </Button>
              <div className="Border" />
              <h2>
                สรุปบิล
              </h2>
              <div className="Summary">
                {
                  summaryCart?.carts.map((mapSum, index) => (
                    <div className="NameSummary" key={`${String(index)}__summary`}>
                      <p>{mapSum.storeName}</p>
                      <p>
                        {mapSum.storeTotalPrice}
                      </p>
                    </div>
                  ))
                }
                <br />
                <div className="NameSummary">
                  <h3><b>ยอดรวม:</b></h3>
                  <p>
                    {formatMoney(summaryCart?.totalPrice, 0)}
                  </p>
                </div>
              </div>
            </div>
            <div style={{ textAlign: 'right' }}>
              <div>
                <Button className="order_btn_1" disabled={!addressId} aria-hidden onClick={onCheckout}>
                  สั่งซื้อสินค้า
                </Button>
              </div>
              <div>
                <Button
                  className="order_btn_2"
                  aria-hidden
                  onClick={() => {
                    clearSummaryCart();
                    router.push('/cart');
                  }}
                >
                  กลับไปรถเข็น
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {modalVisible ? <ModalActionAddress visible={modalVisible} onCancel={() => { _modalVisible(false); _editId(undefined); }} editId={editId} /> : null}
    </>
  );
};

export default CartCheckout;
