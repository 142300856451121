/* eslint-disable max-len */
import { AxiosResponse } from 'axios';
import {
  IOrderCheckoutRequest,
  IOrderDetail, IOrderPaymentRequest, IOrderResponse,
} from '../interface/Order';
import $axios from '../plugin/axios';

export const getAll = async (
  page: number,
  size: number,
):Promise<AxiosResponse<IOrderResponse>> => {
  const response = await $axios.get<IOrderResponse>(`/order/retailer-order?page=${page}&size=${size}`);
  return response;
};

export const getAllStatus = async (
  orderStatus:string,
  page: number,
  size: number,
):Promise<AxiosResponse<IOrderResponse>> => {
  const response = await $axios.get<IOrderResponse>(`/order/retailer-order/${orderStatus}?page=${page}&size=${size}`);
  return response;
};

export const getOne = async (orderId:string):Promise<AxiosResponse<IOrderDetail>> => {
  const response = await $axios.get<IOrderDetail>(`/order/retailer-detail/${orderId}`);
  return response;
};

export const submitPayment = async (form:IOrderPaymentRequest):Promise<AxiosResponse> => {
  const response = await $axios.post('/order/retailer-submit-payment', form);
  return response;
};

export const getAllInvoice = async (
  page: number,
  size: number,
):Promise<AxiosResponse<IOrderResponse>> => {
  const response = await $axios.get<IOrderResponse>(`/order/retailer-invoice?page=${page}&size=${size}`);
  return response;
};

export const getAllInvoiceStatus = async (
  orderStatus:string,
  page: number,
  size: number,
):Promise<AxiosResponse<IOrderResponse>> => {
  const response = await $axios.get<IOrderResponse>(`/order/retailer-invoice/${orderStatus}?page=${page}&size=${size}`);
  return response;
};

export const retailerCheckout = async (form:IOrderCheckoutRequest) => {
  const response = await $axios.post('/order/retailer-checkout', form);
  return response;
};

export const retailerConfirmReceived = async (orderId:string) => {
  const response = await $axios.post('/order/retailer-confirm-received', { orderId });
  return response;
};
