/* eslint-disable no-underscore-dangle */
import { Col, Row, Space } from 'antd';
import { Link } from 'react-router-dom';
import QRCode from 'react-qr-code';
import LogoMiniIcon from '../../common/icons/logo_icon_mini';
import productHook from '../../hooks/product-hook';
import './index.scss';

const BottomNavigation = (): JSX.Element => {
  const { categorys } = productHook();
  const lists = [
    { title: 'หน้าหลัก', path: '/' },
    { title: 'รายการสั่งซื้อ', path: '/order' },
    { title: 'รถเข็น', path: '/cart' },
    // { title: 'ใบเสร็จ', path: '/order-receipt' },
    { title: 'รายการโปรด', path: '/favorite' },
  ];
  return (
    <div className="FooterNavigation">
      <div>
        <Row gutter={[60, 0]} justify="space-between">
          <Col span={6}>
            <div className="header">
              <Space align="end">
                <h3>
                  ติดต่อเรา
                </h3>
                <LogoMiniIcon />
              </Space>
              <div>
                @Line Official
              </div>
            </div>
            <div className="qr__code">
              <QRCode value="https://lin.ee/zwEdo1N" size={124} />
            </div>
          </Col>
          <Col span={11}>
            <div className="header">
              <h3>
                หมวดหมู่
              </h3>
            </div>
            <Row gutter={[24, 12]}>
              {categorys.map((cat, index) => (
                <Col span={6} key={`${String(index)}_cat`}>
                  <Link to={`/category/${cat._id}`}>{cat.name}</Link>
                </Col>
              ))}
            </Row>
          </Col>
          <Col span={6}>
            <div className="header">
              <h3>
                รายการ
              </h3>
            </div>
            <Row gutter={[24, 12]}>
              {lists.map((list, index) => (
                <Col span={24} key={`${String(index)}_list`}>
                  <Link to={list.path}>{list.title}</Link>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BottomNavigation;
