/* eslint-disable react/jsx-props-no-spreading */
import Icon from '@ant-design/icons';
import { FC } from 'react';

const SVG:FC = () => (
  <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.58888 4.10638L3.39432 2.60971C3.32423 1.97523 2.74728 1.49274 2.05873 1.49274H1.33937C0.894252 1.49274 0.533447 1.15856 0.533447 0.746369C0.533447 0.334177 0.894252 0 1.33937 0H2.05873C3.57399 0 4.84357 1.06172 4.99779 2.45779L5.14462 3.78703H21.5001C21.8383 3.78703 22.0923 4.07292 22.0272 4.38024L20.5984 11.1291C20.2525 12.7629 18.7064 13.9413 16.9088 13.9413H8.34504C8.59202 14.9507 9.56595 15.6824 10.702 15.6824H17.4471C17.4868 15.6824 17.5263 15.6851 17.5065 15.6884C17.5655 15.6844 17.6249 15.6824 17.6846 15.6824C19.0196 15.6824 20.1018 16.6847 20.1018 17.9212C20.1018 19.1576 19.0196 20.16 17.6846 20.16C16.3495 20.16 15.2673 19.1576 15.2673 17.9212C15.2673 17.6635 15.3144 17.412 15.4048 17.1751H12.4446C12.535 17.412 12.5822 17.6635 12.5822 17.9212C12.5822 19.1576 11.4999 20.16 10.1649 20.16C8.82984 20.16 7.74761 19.1576 7.74761 17.9212C7.74761 17.3759 7.95952 16.862 8.33192 16.4615C7.41583 15.8436 6.81151 14.8905 6.69434 13.8268L6.68197 13.7144C5.37037 13.252 4.40236 12.1278 4.27342 10.7624L3.94862 7.32301C3.94314 7.30174 3.93911 7.27989 3.93664 7.25754L3.58888 4.10638ZM10.1651 18.9161C10.7584 18.9161 11.2393 18.4707 11.2393 17.9212C11.2393 17.3717 10.7584 16.9262 10.1651 16.9262C9.57182 16.9262 9.09087 17.3717 9.09087 17.9212C9.09087 18.4707 9.57182 18.9161 10.1651 18.9161ZM17.6849 18.9161C18.2782 18.9161 18.7591 18.4707 18.7591 17.9212C18.7591 17.3717 18.2782 16.9262 17.6849 16.9262C17.0916 16.9262 16.6107 17.3717 16.6107 17.9212C16.6107 18.4707 17.0916 18.9161 17.6849 18.9161Z" fill="#1B1D21" />
  </svg>
);

const NavCartIcon: FC = (props) => <Icon component={SVG} {...props} />;

export default NavCartIcon;
