/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import { AxiosResponse } from 'axios';
import { ICreateProcutReview, IProductReviewResponse } from '../interface/ProductReview';
import $axios from '../plugin/axios';

export const getReviewProduct = async (productId: string, page:number, size:number):Promise<AxiosResponse<IProductReviewResponse>> => {
  const response = await $axios.get(`/review/${productId}?page=${page}&size=${size}`);
  return response;
};

export const create = async (form:ICreateProcutReview):Promise<AxiosResponse<IProductReviewResponse>> => {
  const response = await $axios.post('/review', form);
  return response;
};

export const like = async (reviewId:string):Promise<AxiosResponse<IProductReviewResponse>> => {
  const response = await $axios.post('/review/like', { reviewId });
  return response;
};

export const unlike = async (reviewId:string):Promise<AxiosResponse<IProductReviewResponse>> => {
  const response = await $axios.post('/review/unlike', { reviewId });
  return response;
};
