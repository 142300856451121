/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { Button } from 'antd';
import { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { formatMoney } from '../../common/hepler';
import { IOrderProduct } from '../../interface/Order';
import './index.scss';

type IStepOrderConfirm = {
  canNext: boolean
  onNextStep: () => void
  orders: IOrderProduct[]
  storeName: string | undefined
}

const StepOrderConfirm: FC<IStepOrderConfirm> = ({
  canNext, orders, storeName, onNextStep,
}: IStepOrderConfirm) => {
  const router = useHistory();
  return (
    <div className="step__confirm">
      <h1>
        {storeName || ''}
      </h1>
      <div>
        <table>
          <thead>
            <tr>
              <th>
                สินค้า
              </th>
              <th>
                ราคา
              </th>
              <th>
                จำนวน
              </th>
              <th>
                ราคารวม
              </th>
            </tr>
          </thead>
          <tbody>
            {orders.map((ordp: IOrderProduct, index: number) => (
              <tr key={`${String(index)}__ordp`}>
                <td>
                  <Link className="product__col" to={`/product/${ordp.product._id}`}>
                    <div className="product_img" style={{ backgroundImage: `url(${ordp.variantSaleInfo.image?.url || ''})` }} />
                    <div className="product_des">
                      <div className="product_name">
                        {ordp.product.product}
                      </div>
                      <div className="product_variant">
                        {ordp.variantSaleInfo.var1.name}
                      </div>
                      <div className="product_variant">
                        {ordp.variantSaleInfo.var2?.name || ''}
                      </div>
                    </div>
                  </Link>
                </td>
                <td>
                  {formatMoney(ordp.variantSaleInfo.price)}
                  {' '}
                  บาท
                </td>
                <td>
                  <div className="product_qty">
                    {ordp.amount}
                  </div>
                </td>
                <td>
                  {formatMoney(ordp.productTotalPrice)}
                  {' '}
                  บาท
                </td>
              </tr>
            ))}

          </tbody>
        </table>
      </div>
      <div className="btn_next">
        <Button onClick={() => router.goBack()} style={{ marginRight: 12, background: 'white', color: '#535252' }}>ย้อนกลับ</Button>
        <Button className={!canNext ? 'disabled_btn' : ''} disabled={!canNext} onClick={onNextStep}>ถัดไป</Button>
      </div>
    </div>
  );
};

export default StepOrderConfirm;
