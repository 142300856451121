/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Form, Input, Button, Checkbox, Select, Space, Row, Col,
} from 'antd';
import { debounce } from 'lodash';
import { LeftOutlined } from '@ant-design/icons';
import type { ICreateWholesaler } from '../../../interface/RegisterForm';
import './index.scss';
import LogoIcon from '../../../common/icons/logo_icon';
import AuthHook from '../../../hooks/auth-hook';
import { getCountry, getProvince, verifyWholesalerStoreName } from '../../../services/auth-service';
import { ICountry, IProvince } from '../../../interface/Address';
import productHook from '../../../hooks/product-hook';

const { TextArea } = Input;
const { Option } = Select;

const RegisterWholesalerForm = ():JSX.Element => {
  const router = useHistory();
  const [form] = Form.useForm();

  const { registerEmail, registerWholesaler } = AuthHook();
  const { categorys } = productHook();

  const [canStoreName, _canStoreName] = useState<boolean | undefined>();
  const [country, _country] = useState<ICountry[]>([]);
  const [province, _province] = useState<IProvince[]>([]);

  const fetchCountry = async () => {
    const { data } = await getCountry();
    _country(data);
  };

  const OnSubmit = async (value:any) => {
    try {
      const formData:ICreateWholesaler = {
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        password: value.password,
        storeName: value.storeName,
        juristicName: value.juristicName,
        juristicNumber: value.juristicNumber,
        webLink: value.webLink,
        categories: value.categories,
        phoneNumber: value.phoneNumber,
        address: {
          address: value.address,
          province: value.province,
          country: value.country,
          postalCode: value.postalCode,
        },
      };
      const register = await registerWholesaler(formData);
      if (register) {
        router.push('/register/complete');
      } else {
        alert('สมัครสมาชิกไม่สำเร็จ !');
      }
    } catch (err) {
      alert('สมัครสมาชิกไม่สำเร็จ !');
    }
  };

  const onCountryChange = async (countryId:string) => {
    const { data } = await getProvince(countryId);
    _province(data);
  };

  const tagRender = (props:any) => {
    const {
      // eslint-disable-next-line react/prop-types
      label, onClose,
    } = props;

    return (
      <div className="customer__selector">
        {label}
        <Button size="small" type="text" style={{ marginLeft: 4 }} onClick={onClose}>
          <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.90936 4.50001L8.1987 2.21067C8.38865 2.03072 8.49862 1.78079 8.49862 1.50087C8.49833 1.30295 8.43948 1.10953 8.32946 0.944995C8.21945 0.780458 8.06321 0.652159 7.88041 0.576261C7.69762 0.500363 7.49645 0.480262 7.30225 0.518489C7.10805 0.556716 6.9295 0.651561 6.78911 0.791077L4.49977 3.09042L2.21043 0.791077C2.02218 0.602828 1.76685 0.49707 1.50063 0.49707C1.2344 0.49707 0.979083 0.602828 0.790833 0.791077C0.602584 0.979327 0.496826 1.23465 0.496826 1.50087C0.496826 1.7671 0.602584 2.02242 0.790833 2.21067L3.09017 4.50001L0.800831 6.78935C0.610885 6.9693 0.500916 7.21923 0.500916 7.49915C0.501202 7.69708 0.560058 7.89049 0.67007 8.05503C0.780081 8.21956 0.936327 8.34786 1.11912 8.42376C1.30192 8.49966 1.50309 8.51976 1.69729 8.48153C1.89149 8.44331 2.07003 8.34846 2.21043 8.20895L4.49977 5.90961L6.78911 8.19895C6.96906 8.38889 7.21899 8.49886 7.49891 8.49886C7.69683 8.49858 7.89025 8.43972 8.05478 8.32971C8.21932 8.2197 8.34762 8.06345 8.42352 7.88066C8.49942 7.69786 8.51952 7.49669 8.48129 7.30249C8.44306 7.10829 8.34822 6.92975 8.2087 6.78935L5.90936 4.50001Z" fill="#080C16" />
          </svg>
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (!registerEmail) {
      router.push('/');
    } else {
      form.setFieldsValue({
        email: registerEmail,
      });
    }
    fetchCountry();
  }, []);

  const validateMessages = {
    required: 'is required!',
    // ...
  };

  const checkStoreName = useCallback(
    debounce(async (text:string) => {
      const { data: { available } } = await verifyWholesalerStoreName(text);
      try {
        _canStoreName(available);
      } catch (err) {
        console.log(err);
      }
    }, 1000),
    [],
  );

  return (
    <>
      <LeftOutlined
        onClick={() => {
          router.goBack();
        }}
        style={{ margin: '25px 0 0 65px', position: 'absolute', fontSize: '20px' }}
      />
      <div className="RegisterForm">
        <div className="header">
          <Space align="center">
            <h2>
              สมัครเพื่อขายกับ
            </h2>
            <LogoIcon />
          </Space>
        </div>
        <p>
          กรอกข้อมูลเกี่ยวกับร้านค้าของคุณ เพื่อยืนยันตัวตน
        </p>
        <Form
          form={form}
          layout="vertical"
          onFinish={OnSubmit}
          requiredMark={false}
          validateMessages={validateMessages}
        >
          <Row gutter={[32, 16]} align="middle">
            <Col span={12}>
              <Form.Item
                name="firstName"
                label="ชื่อ"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกชื่อ',
                  },
                  {
                    min: 2,
                    message: 'ชื่อต้องมีอย่างน้อย 2 ตัวอักษรขึ้นไป',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="lastName"
                label="นามสกุล"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกนามสกุล',
                  },
                  {
                    min: 2,
                    message: 'นามสกุลต้องมีอย่างน้อย 2 ตัวอักษรขึ้นไป',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอก Email',
                  },
                ]}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกรหัสผ่าน',
                  },
                  {
                    min: 4,
                    message: 'รหัสผ่านต้องมีอย่างน้อย 4 ตัวอักษรขึ้นไป',
                  },
                ]}
              >
                <Input type="password" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="rePassword"
                label="Re-password"
                rules={[
                  {
                    validator: (rule, value, callback) => {
                      const pwd = form.getFieldValue('password');
                      if (!value) {
                        callback('กรุณากรอกยืนยันรหัสผ่าน');
                      } else if (value !== pwd) {
                        callback('ยืนยันรหัสผ่านไม่ถูกต้อง');
                      } else {
                        callback(undefined);
                      }
                    },
                  },
                ]}
              >
                <Input type="password" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="storeName"
                label="ชื่อร้านค้า"
                rules={[
                  {
                    validator: (rule, value, callback) => {
                      if (!value) {
                        callback('กรุณากรอกชื่อร้านค้า');
                      } else if (value.length <= 2) {
                        callback('ชื่อร้านค้าต้องมีอย่างน้อย 2 ตัวอักษรขึ้นไป');
                      } else if (canStoreName === false) {
                        callback('กรุณาเปลี่ยนชื่อร้านค้า');
                      } else {
                        callback(undefined);
                      }
                    },
                  },
                ]}
              >
                <Input onChange={(e) => {
                  _canStoreName(undefined);
                  checkStoreName(e.currentTarget.value);
                }}
                />
              </Form.Item>
            </Col>

            <Col span={12} style={{ paddingTop: 14 }}>
              {
                canStoreName !== undefined
                  ? (
                    <>
                      {
                      canStoreName
                        ? (
                          <Space align="start">
                            <div>
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.672 4.464L6.4 9.736L3.528 6.872L2.4 8L6.4 12L12.8 5.6L11.672 4.464ZM8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8 14.4C4.464 14.4 1.6 11.536 1.6 8C1.6 4.464 4.464 1.6 8 1.6C11.536 1.6 14.4 4.464 14.4 8C14.4 11.536 11.536 14.4 8 14.4Z" fill="#979797" />
                              </svg>
                            </div>
                            <div style={{ lineHeight: '18px' }}>
                              ชื่อร้านค้านี้สามารถใช้ได้
                            </div>
                          </Space>
                        )
                        : (
                          <Space align="start">
                            <div>
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.839 12.7904L9.24928 1.35317C9.12985 1.14604 8.95034 0.972567 8.73019 0.851528C8.51004 0.730489 8.25765 0.666504 8.00035 0.666504C7.74305 0.666504 7.49066 0.730489 7.27051 0.851528C7.05036 0.972567 6.87085 1.14604 6.75143 1.35317L0.160975 12.7904C0.0483995 12.9857 -0.00681482 13.2042 0.000671534 13.4246C0.00815789 13.645 0.0780916 13.8599 0.20371 14.0485C0.329328 14.2372 0.506381 14.3932 0.717746 14.5014C0.92911 14.6097 1.16764 14.6665 1.41026 14.6665H14.5897C14.8324 14.6665 15.0709 14.6097 15.2823 14.5014C15.4936 14.3932 15.6707 14.2372 15.7963 14.0485C15.9219 13.8599 15.9918 13.645 15.9993 13.4246C16.0068 13.2042 15.9516 12.9857 15.839 12.7904V12.7904ZM7.29499 5.37254C7.29499 5.20261 7.36927 5.03963 7.50148 4.91947C7.6337 4.79931 7.81302 4.7318 8 4.7318C8.18698 4.7318 8.3663 4.79931 8.49852 4.91947C8.63073 5.03963 8.70501 5.20261 8.70501 5.37254V9.217C8.70501 9.38693 8.63073 9.54991 8.49852 9.67007C8.3663 9.79023 8.18698 9.85774 8 9.85774C7.81302 9.85774 7.6337 9.79023 7.50148 9.67007C7.36927 9.54991 7.29499 9.38693 7.29499 9.217V5.37254ZM8.03525 12.7475H8.01551C7.73748 12.7467 7.47056 12.6482 7.27058 12.4727C7.0706 12.2971 6.95304 12.0581 6.94248 11.8056C6.9374 11.68 6.95991 11.5548 7.00869 11.4372C7.05747 11.3196 7.13155 11.212 7.22659 11.1208C7.32163 11.0295 7.43574 10.9563 7.56224 10.9056C7.68874 10.8549 7.82509 10.8276 7.96334 10.8253H7.98308C8.26105 10.8256 8.52813 10.9236 8.72839 11.0988C8.92866 11.274 9.0466 11.5128 9.05752 11.7652C9.06299 11.8911 9.04071 12.0167 8.99201 12.1346C8.94331 12.2525 8.86916 12.3604 8.77392 12.4519C8.67868 12.5435 8.56427 12.6167 8.43742 12.6675C8.31057 12.7183 8.17383 12.7454 8.03525 12.7475V12.7475Z" fill="#D64E30" />
                              </svg>
                            </div>
                            <div style={{ lineHeight: '18px', color: '#D64E30' }}>
                              ไม่สามารถใช้ชื่อนี้ได้
                            </div>
                          </Space>
                        )
                    }
                    </>
                  )
                  : null
              }
            </Col>

            <Col span={12}>
              <Form.Item
                name="juristicName"
                label="ชื่อนิติบุคคล"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกชื่อนิติบุคคล',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="juristicNumber"
                label="เลขที่นิติบุคคล/บริษัท"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกเลขที่นิติบุคคล/บริษัท',
                  },
                  {
                    pattern: /^[0-9]{13}$/,
                    message: 'กรุณากรอกเลขที่นิติบุคคล/บริษัท 13 หลัก ให้ถูกต้อง',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="address"
                label="ที่อยู่ร้านค้า/บริษัท"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกที่อยู่ร้านค้า/บริษัท',
                  },
                  {
                    min: 10,
                    message: 'ที่อยู่ร้านค้า/บริษัทต้องมีอย่างน้อย 10 ตัวอักษรขึ้นไป',
                  },
                ]}
              >
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="country"
                label="ประเทศ"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกประเทศ',
                  },
                ]}
              >
                <Select onChange={onCountryChange}>
                  {country.map((c, index) => (
                    <Option value={c._id} key={`country_${String(index)}`}>
                      {c.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="province"
                label="จังหวัด"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกจังหวัด',
                  },
                ]}
              >
                <Select>
                  {province.map((p, index) => <Option value={p._id} key={`province_${String(index)}`}>{p.name}</Option>)}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="postalCode"
                label="รหัสไปรษณีย์"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกรหัสไปรษณีย์',
                  },
                  {
                    pattern: /^[0-9]{5}$/,
                    message: 'กรุณากรอกรหัสไปรษณีย์ให้ถูกต้อง',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="phoneNumber"
                label="เบอร์โทรศัพท์"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกเบอร์โทรศัพท์',
                  },
                  {
                    pattern: /^[0-9]{9,10}$/,
                    message: 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="webLink"
                label="ลิ้งค์เว็บไซต์ของร้านค้า"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกลิ้งค์เว็บไซต์ของร้านค้า',
                  },
                  // eslint-disable-next-line no-useless-escape
                  { pattern: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm, message: 'กรุณากรอกลิ้งค์เว็บไซต์ของร้านค้าให้ถูกต้อง ' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="categories"
                label="หมวดหมู่สินค้าของร้านค้า"
                rules={[
                  {
                    required: true,
                    message: 'กรุณาเลือกหมวดหมู่สินค้าของร้านค้า',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="กรุณาเลือก"
                  tagRender={tagRender}
                >
                  {categorys.map((cat, index) => (
                    <Option key={`cat__${String(index)}`} value={cat._id}>{cat.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item>
                <Button className="SubmitForm" type="primary" htmlType="submit">สมัครสมาชิก</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default RegisterWholesalerForm;
