/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-alert */
/* eslint-disable max-len */
import {
  Button, Col, Form, Input, Modal, Radio, Row, Select,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AccountEmailIcon from '../../common/icons/account_email';
import AccountPasswordIcon from '../../common/icons/account_icon';
import AddressHook from '../../hooks/address-hook';
import {
  IAddress,
  ICountry, ICreateAddress, IEditAddress, IProvince,
} from '../../interface/Address';
import { IUserLogin } from '../../interface/User';
import {
  getCountry, getProvince,
} from '../../services/auth-service';
import './index.scss';

type IModalActionAddress = {
    visible: boolean
    onCancel: () => void
    editId?: string
}

const ModalActionAddress = ({ visible, onCancel, editId } : IModalActionAddress): JSX.Element => {
  const [form] = Form.useForm<ICreateAddress|IEditAddress>();
  const {
    createAddress, editAddress, getAddressById, fetchAddress,
  } = AddressHook();
  const [editAddressById, _editAddressById] = useState<IAddress | undefined>();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const onFinish = async (values: ICreateAddress|IEditAddress) => {
    try {
      if (editAddressById) {
        const form:IEditAddress = { ...values, id: editAddressById._id };
        await editAddress(form);
      } else {
        await createAddress(values);
      }
      await fetchAddress();
      onCancel();
    } catch (err) {
      alert('ดำเนินการไม่สำเร็จ');
    }
  };

  const onFinishFailed = async () => {
    console.log('failed');
  };

  const router = useHistory();

  const [country, _country] = useState<ICountry[]>([]);
  const [province, _province] = useState<IProvince[]>([]);

  const fetchCountry = async () => {
    const { data } = await getCountry();
    _country(data);
  };

  const onCountryChange = async (countryId:string) => {
    const { data } = await getProvince(countryId);
    _province(data);
  };

  useEffect(() => {
    fetchCountry();
  }, []);

  useEffect(() => {
    if (editId) {
      const data = getAddressById(editId);
      form.setFieldsValue({
        fullName: data?.fullName,
        phoneNumber: data?.phoneNumber,
        address: data?.address,
        province: data?.province._id,
        country: data?.country._id,
        postalCode: data?.postalCode,
      });
      onCountryChange(data?.country?._id || '');
      _editAddressById(data);
    } else {
      _editAddressById(undefined);
    }
  }, [editId]);

  return (
    <Modal title={null} footer={null} visible={visible} onCancel={handleCancel} closable>
      <div className="modal_action_address">
        <Form
          layout="vertical"
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
          requiredMark={false}
        >
          <Row gutter={[12, 8]} align="middle">
            <Col span={24}>
              <Form.Item
                name="fullName"
                label="ชื่อ/ชื่อร้านค้า"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกชื่อ/ชื่อร้านค้า',
                  },
                  {
                    min: 2,
                    message: 'กรุณากรอกชื่อ/ชื่อร้านค้าต้องมีความยาวมากกว่า 2 ตัวอักษร',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="address"
                label="ที่อยู่ร้านค้า/บริษัท"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกที่อยู่ร้านค้า/บริษัท',
                  },
                  {
                    min: 10,
                    message: 'ที่อยู่ร้านค้า/บริษัทต้องมีความยาวมากกว่า 10 ตัวอักษร',
                  },
                ]}
              >
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="country"
                label="ประเทศ"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกประเทศ',
                  },
                ]}
              >
                <Select onChange={onCountryChange}>
                  {country.map((c, index) => (
                    <Select.Option value={c._id} key={`country_${String(index)}`}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="province"
                label="จังหวัด"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกจังหวัด',
                  },
                ]}
              >
                <Select>
                  {province.map((p, index) => <Select.Option value={p._id} key={`province_${String(index)}`}>{p.name}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="postalCode"
                label="รหัสไปรษณีย์"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกรหัสไปรษณีย์',
                  },
                  {
                    pattern: /^[0-9]{5}$/,
                    message: 'กรุณากรอกรหัสไปรษณีย์ให้ถูกต้อง',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="phoneNumber"
                label="เบอร์โทรศัพท์"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกเบอร์โทรศัพท์',
                  },
                  {
                    pattern: /^[0-9]{9,10}$/,
                    message: 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ textAlign: 'right', marginTop: 8 }}>
            <Button className="btn cancel" onClick={onCancel}>ยกเลิก</Button>
            <Button htmlType="submit" className="btn confirm">{editAddressById ? 'แก้ไข' : 'ยืนยัน'}</Button>
          </div>
        </Form>
      </div>

    </Modal>

  );
};

export default ModalActionAddress;
