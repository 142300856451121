/* eslint-disable consistent-return */
/* eslint-disable radix */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */

import React from 'react';
import { useLocation } from 'react-router-dom';

export const twoDigit = (digit: string) => {
  if (digit.length === 1) {
    return `0${digit}`;
  }
  return digit;
};

export const checkText = (digit: string | undefined) => {
  if (digit === undefined || digit === '' || digit === null) {
    return '-';
  }
  return digit;
};

export const formatDateTime = (date: string | Date | undefined): string => {
  try {
    if (date) {
      const alldate = new Date(date);
      const dd = alldate.getDate();
      const mm = alldate.getMonth();
      const yy = alldate.getFullYear();

      const hh = alldate.getHours();
      const mn = alldate.getMinutes();

      const months = [
        'Jan',
        'Feb',
        'March',
        'Apr',
        'May',
        'Jul',
        'July',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];

      const prefix = hh >= 12 ? 'PM' : 'AM';
      const euh = prefix === 'AM' ? hh : hh - 12;

      const value = `${twoDigit(String(dd))} ${months[mm]}, ${String(yy)} ${twoDigit(String(euh))}.${twoDigit(String(mn))} ${prefix}`;
      return String(value);
    }
    return String(date);
  } catch (err) {
    return String(date);
  }
};

export const formatMoney = (amount: any, decimalCount = 2, decimal = '.', thousands = ',') => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign
      + (j ? i.substr(0, j) + thousands : '')
      + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`)
      + (decimalCount
        ? decimal
          + Math.abs(amount - parseInt(i))
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
};

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};
