import { Button, Carousel } from 'antd';
import AuthHook from '../../../hooks/auth-hook';
import './index.scss';

const Slides = (): JSX.Element => {
  const { accessToken, setVisibleModalRegister } = AuthHook();
  return (
    <div className="sldes_components">
      <Carousel autoplay dots>
        <div
          className="carousel__child slide_1"
        />
        <div
          className="carousel__child slide_2"
        />
        <div
          className="carousel__child slide_3"
        />
      </Carousel>
      {/* <div className="sub__text">
        <div>
          <div>
            Discover New Amazing
          </div>
          <div>
            Wholesale Deals
          </div>
        </div>
        <div>
          <div>
            An e-commerce website for Thai wholesale.
          </div>
          <div>
            To be one part of our website, register for free!
          </div>
        </div>
        <div>
          {accessToken ? null
            : (
              <Button size="large" onClick={() => setVisibleModalRegister(true)}>
                สมัครสมาชิก
              </Button>
            )}
        </div>
      </div> */}
    </div>
  );
};

export default Slides;
