/* eslint-disable no-underscore-dangle */
import { HeartFilled } from '@ant-design/icons';
import productHook from '../../hooks/product-hook';
import { IProduct } from '../../interface/Product';
import './index.scss';

const ProductFavorite = ({ product }: {product:IProduct}) => {
  const { unLikeProduct, likeProduct } = productHook();

  const onLikeClick = async () => {
    await likeProduct(product._id);
  };

  const onUnLikeClick = async () => {
    if (product.isFav) {
      await unLikeProduct(product.isFav, product._id);
    }
  };

  const Like = () => (
    <div aria-hidden onClick={onLikeClick} className="Fav">
      <HeartFilled style={{ color: '#535252' }} />
    </div>
  );

  const UnLike = () => (
    <div aria-hidden onClick={onUnLikeClick} className="Fav">
      <HeartFilled style={{ color: '#FF4A4A' }} />
    </div>
  );

  return (
    <>
      {product.isFav ? <UnLike /> : <Like />}
    </>
  );
};

export default ProductFavorite;
