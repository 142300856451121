/* eslint-disable no-useless-catch */
import { useAppDispatch, useAppSelector } from '../reducers/store';
import * as paymentInfoService from '../services/payment-service';
import * as paymentInfoSlice from '../reducers/state/payment-slice';
import { IPaymentInfo } from '../interface/Payment';

const PaymentInfoHook = () => {
  const { paymentInfo, paymentType, isLoading } = useAppSelector((state) => state.paymentInfo);
  const dispatch = useAppDispatch();

  const fetchPaymentInfo = async () => {
    try {
      dispatch(paymentInfoSlice.setLoading(true));
      const { data } = await paymentInfoService.getPaymentInfo();
      dispatch(paymentInfoSlice.setPaymentInfo(data));
    } catch (err) {
      // throw err;
    } finally {
      dispatch(paymentInfoSlice.setLoading(false));
    }
  };

  const fetchPaymentType = async () => {
    try {
      dispatch(paymentInfoSlice.setLoading(true));
      const { data } = await paymentInfoService.getPaymentType();
      dispatch(paymentInfoSlice.setPaymentType(data));
    } catch (err) {
      // throw err;
    } finally {
      dispatch(paymentInfoSlice.setLoading(false));
    }
  };

  const addPaymentInfo = async (form:IPaymentInfo) => {
    try {
      dispatch(paymentInfoSlice.setLoading(true));
      const { data } = await paymentInfoService.addPaymentInfo(form);
      console.log(data);
    } catch (err) {
      throw err;
    } finally {
      dispatch(paymentInfoSlice.setLoading(false));
    }
  };

  return {
    fetchPaymentInfo,
    fetchPaymentType,
    addPaymentInfo,
    paymentInfo,
    paymentType,
    isLoading,
  };
};

export default PaymentInfoHook;
