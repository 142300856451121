/* eslint-disable no-plusplus */
import { Button, Divider } from 'antd';
import { useEffect, useState } from 'react';
import productHook from '../../hooks/product-hook';
import './index.scss';
import ProductFavorite from './productFavorite';

const Favorite = ():JSX.Element => {
  const {
    fetchFavProduct, favProducts, totalFav,
  } = productHook();

  const [page, _page] = useState(1);
  const size = 12;

  const nowPage = page * size;

  const fetchData = async () => {
    await fetchFavProduct(page, size);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onUpdate = async () => {
    _page(1);
    await fetchFavProduct(1, size);
  };

  return (
    <div className="Favorite">
      <h2 className="Title">
        รายการโปรด
      </h2>
      <div>
        {favProducts.length > 0
          ? <ProductFavorite products={favProducts} onUpdate={onUpdate} /> : (
            <h3 style={{ marginTop: 70, textAlign: 'center', color: '#535252' }}>
              ไม่พบข้อมูล...
            </h3>
          )}
        {nowPage >= totalFav ? null
          : (
            <div className="more__btn">
              <Divider>
                <Button onClick={() => {
                  const newPage = page + 1;
                  fetchFavProduct(newPage, size);
                  _page(newPage);
                }}
                >
                  ดูเพิ่มเติม
                </Button>
              </Divider>
            </div>
          )}
      </div>
    </div>
  );
};

export default Favorite;
