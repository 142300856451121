/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import { useHistory } from 'react-router';
import { Button, Rate } from 'antd';
import { IProduct } from '../../interface/Product';
import AuthHook from '../../hooks/auth-hook';
import './index.scss';
import ProductFavorite from '../ProductFavorite';
import { formatMoney } from '../../common/hepler';

type ICardProduct = {
  product: IProduct
}
const CardProducts = ({ product }:ICardProduct):JSX.Element => {
  const { accessToken, setVisibleModalLogin } = AuthHook();
  const router = useHistory();
  const addToCart = async () => {
    if (!accessToken) {
      setVisibleModalLogin(true);
    } else {
      router.push(`/product/${product._id}`);
      window.scrollTo(0, 0);
    }
  };

  const imageUrl = product?.images[0]?.url || '';
  return (
    <div className="CardProducts">
      <div
        className="product_image"
        onClick={() => addToCart()}
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      <div className="Detail">
        <div className="ProductDetail">
          <p className="ProductName">
            {product.product}
          </p>
          {accessToken
            ? (
              <p className="Price">
                {formatMoney(product.minPrice, 0)}
                {' '}
                บาท
              </p>
            )
            : null}
          <Rate className="Rate" allowHalf disabled defaultValue={product.rate || 0} />
          <Button aria-hidden onClick={() => addToCart()}>
            เพิ่มไปยังรถเข็น
          </Button>
        </div>
        {accessToken
          ? (
            <ProductFavorite product={product} />
          )
          : null}
      </div>
    </div>
  );
};

export default CardProducts;
