/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
} from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Controller, useForm,
} from 'react-hook-form';
import { formatMoney } from '../../common/hepler';
import TableCart from '../../components/TableCart';
import CartHook from '../../hooks/cart-hook';
import { ICartProduct } from '../../interface/Cart';
import './index.scss';

const Cart = (): JSX.Element => {
  const {
    handleSubmit, control, watch,
  } = useForm({ reValidateMode: 'onChange' });
  const router = useHistory();
  const {
    carts, fetchCart, fetchSummaryCart, summaryCart,
  } = CartHook();

  const [summary, _summary] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: string]: any;
  }>();

  const fetchData = async () => {
    await fetchCart();
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (summaryCart) {
      router.push('/cart-checkout');
    }
  }, [summaryCart]);

  const setSummaryCart = (value: {
    [x: string]: any;
  }) => {
    _summary(value);
  };

  const calTotal = (list: ICartProduct[]) => list.reduce((prev, cur) => prev + (cur.amount * cur.variantSaleInfo.price), 0);

  useEffect(() => {
    const subscription = watch((value) => setSummaryCart(value));
    return () => subscription.unsubscribe();
  }, [watch, carts]);

  const checkMin = (val: any) => {
    try {
      const min = val.minimumStore;
      const cost = val.list.reduce((prev: number, cur: { amount: number; variantSaleInfo: { price: number; }; }) => prev + (cur.amount * cur.variantSaleInfo.price), 0) || 0;
      if (cost === 0) {
        return true;
      }
      if (cost < min) {
        return false;
      }
      return true;
    } catch (err) {
      return false;
    }
  };

  const xxLs: any[] = [];

  for (const key in summary) {
    if (summary[key].list.length > 0) {
      xxLs.push(summary[key]);
    }
  }

  const onSubmitCheckout = async () => {
    if (!xxLs || xxLs.length <= 0) return;
    const cartIds = [];
    for (let i = 0; i < xxLs.length; i++) {
      for (let j = 0; j < xxLs[i].list.length; j++) {
        const list = xxLs[i]?.list[j];
        if (list) {
          cartIds.push(list._id);
        }
      }
    }
    await fetchSummaryCart(cartIds);
  };

  return (
    <div className="Cart">
      <h2>
        รถเข็น
      </h2>
      <form className="Body" onSubmit={handleSubmit(onSubmitCheckout)}>
        <div className="Store">
          {carts.length > 0 ? carts.map((ca, index) => (
            <Controller
              key={`ca__${String(index)}`}
              defaultValue={undefined}
              control={control}
              name={`ca__${String(index)}`}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <TableCart
                  cart={ca}
                  onRowSelection={(cart) => onChange(cart)}
                  errorTable={error}
                />
              )}
              rules={{ validate: checkMin }}
            />
          )) : <div>ไม่มีสินค้าในตะกร้า...</div>}
        </div>
        <div className="Summary">
          <h3>
            สรุปบิล
          </h3>
          {xxLs?.map((smr, index) => (
            <div className="NameSummary" key={`smr__${String(index)}`}>
              <p>
                {index + 1}
                .
                {' '}
                {smr?.storeName}
              </p>
              <p>
                {formatMoney(calTotal(smr.list), 0)}
                {' '}
                บาท
              </p>
            </div>
          ))}

          <div className="NameSummary">
            <p>ยอดรวม</p>
            <p>
              {formatMoney(xxLs.reduce((prev, cur) => prev + calTotal(cur.list), 0), 0)}
              {' '}
              บาท
            </p>
          </div>
          <Button htmlType="submit" className="Order">
            สั่งสินค้า
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Cart;
