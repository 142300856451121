import { combineReducers } from '@reduxjs/toolkit';
import orderReducer from './state/order-slice';
import productReducer from './state/product-slice';
import paymentInfoReducer from './state/payment-slice';
import authReducer from './state/auth-slice';
import cartReducer from './state/cart-slice';
import addressReducer from './state/address.slice';

const rootReducer = combineReducers({
  auth: authReducer,
  order: orderReducer,
  product: productReducer,
  paymentInfo: paymentInfoReducer,
  cart: cartReducer,
  address: addressReducer,
});

export default rootReducer;
