/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { Col, Row } from 'antd';
import CardProductFav from '../../../components/CardProductFav';
import { IFavProductDetail } from '../../../interface/Product';

const ProductFavorite = ({ products, onUpdate }:{products:IFavProductDetail[], onUpdate:() => void}):JSX.Element => (
  <Row gutter={[24, 24]}>
    {products.map((fv, index) => <Col span={12} xs={12} md={6} key={`${String(index)}__fv`}><CardProductFav product={fv.product} favId={fv._id} onUpdate={onUpdate} /></Col>)}
  </Row>
);

export default ProductFavorite;
