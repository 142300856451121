/* eslint-disable max-len */
/* eslint-disable no-useless-catch */
import { useAppDispatch, useAppSelector } from '../reducers/store';
import * as productService from '../services/product-service';
import * as productReviewService from '../services/product-review-service';
import * as productSlice from '../reducers/state/product-slice';
import {
  ICreateProduct, IUpdateProduct,
} from '../interface/Product';
import { ICreateProcutReview, IProductReviewResponse } from '../interface/ProductReview';

const productHook = () => {
  const {
    products, categorys, units, isLoading, productReviews, total,
    favProducts,
    totalFav,
    totalReviews,
  } = useAppSelector((state) => state.product);
  const dispatch = useAppDispatch();

  // getters

  const favoriteProducts = products.filter((p) => p.isFav !== undefined);

  const fetchFavorite = async () => {
    try {
      const { data } = await productService.getFavorite();
      dispatch(productSlice.setProductFavorite(data));
    } catch (err) {
      console.log(err);
    }
  };

  const fetchProduct = async (query = '', page = 1, size = 10, sort = '') => {
    try {
      dispatch(productSlice.setLoading(true));
      const { data } = await productService.getProduct(query, page, size, sort);
      if (page === 1) {
        dispatch(productSlice.setProduct(data));
      } else if (page > 1) {
        dispatch(productSlice.addProduct(data));
      }
      await fetchFavorite();
    } catch (err) {
      // throw err;
    } finally {
      dispatch(productSlice.setLoading(false));
    }
  };

  const fetchProductRecommed = async () => {
    try {
      dispatch(productSlice.setLoading(true));
      const { data } = await productService.getProductRecommend();
      dispatch(productSlice.setProduct(data));
      await fetchFavorite();
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(productSlice.setLoading(false));
    }
  };

  const findProduct = async (productId:string) => {
    try {
      dispatch(productSlice.setLoading(true));
      const { data } = await productService.findProduct(productId);
      return data;
    } catch (err) {
      return undefined;
    } finally {
      dispatch(productSlice.setLoading(false));
    }
  };

  const createProduct = async (formData:ICreateProduct) => {
    try {
      dispatch(productSlice.setLoading(true));
      const { data } = await productService.create(formData);
      console.log('create product => ', data);
    } catch (err) {
      // throw err;
    } finally {
      dispatch(productSlice.setLoading(false));
    }
  };

  const updateProduct = async (formData:IUpdateProduct) => {
    try {
      dispatch(productSlice.setLoading(true));
      const { data } = await productService.update(formData);
      console.log('update product => ', data);
    } catch (err) {
      // throw err;
    } finally {
      dispatch(productSlice.setLoading(false));
    }
  };

  const fetchCategory = async () => {
    try {
      dispatch(productSlice.setLoading(true));
      const { data } = await productService.getCategory();
      dispatch(productSlice.setCategory(data));
    } catch (err) {
      // throw err;
    } finally {
      dispatch(productSlice.setLoading(false));
    }
  };

  const fetchUnit = async () => {
    try {
      dispatch(productSlice.setLoading(true));
      const { data } = await productService.getUnit();
      dispatch(productSlice.setUnit(data));
    } catch (err) {
      // throw err;
    } finally {
      dispatch(productSlice.setLoading(false));
    }
  };

  const setLoading = (flag:boolean) => {
    dispatch(productSlice.setLoading(flag));
  };

  const fetchProductReview = async (productId: string, page = 1, size = 10) => {
    try {
      dispatch(productSlice.setLoading(true));
      const { data } = await productReviewService.getReviewProduct(productId, page, size);
      dispatch(productSlice.setProductReview(data));
    } catch (err) {
      // throw err;
    } finally {
      dispatch(productSlice.setLoading(false));
    }
  };

  const fetchProductAddReview = async (productId:string, page = 1, size = 10) => {
    try {
      dispatch(productSlice.setLoading(true));
      const { data } = await productReviewService.getReviewProduct(productId, page, size);
      const update :IProductReviewResponse = {
        docs: [...productReviews, ...data.docs],
        size,
        page,
        total: data.total,
      };
      dispatch(productSlice.setProductReview(update));
    } catch (err) {
      // throw err;
    } finally {
      dispatch(productSlice.setLoading(false));
    }
  };

  const likeProduct = async (productId:string) => {
    try {
      setLoading(true);
      await productService.like(productId);
      await fetchFavorite();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const unLikeProduct = async (favoriteId:string, productId:string) => {
    try {
      setLoading(true);
      await productService.unLike(favoriteId, productId);
      await fetchFavorite();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchFavProduct = async (page = 1, size = 12) => {
    try {
      const { data } = await productService.getFavoriteDetail(page, size);
      if (page === 1) {
        dispatch(productSlice.setFavProduct(data));
      } else if (page > 1) {
        dispatch(productSlice.addFavProduct(data));
      }
    } catch (err) {
      // throw err;
    } finally {
      dispatch(productSlice.setLoading(false));
    }
  };

  const reviewProduct = async (form:ICreateProcutReview) => {
    try {
      setLoading(true);
      const { data } = await productReviewService.create(form);
      console.log(data);
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const likeReview = async (reviewId:string) => {
    try {
      setLoading(true);
      await productReviewService.like(reviewId);
    } catch (err) {
      throw (err);
    } finally {
      setLoading(false);
    }
  };

  const unLikeReview = async (reviewId:string) => {
    try {
      setLoading(true);
      await productReviewService.unlike(reviewId);
    } catch (err) {
      throw (err);
    } finally {
      setLoading(false);
    }
  };

  return {
    fetchFavProduct,
    findProduct,
    fetchProduct,
    fetchProductRecommed,
    createProduct,
    updateProduct,
    fetchCategory,
    fetchUnit,
    fetchProductReview,
    fetchFavorite,
    setLoading,
    likeProduct,
    unLikeProduct,
    reviewProduct,
    likeReview,
    fetchProductAddReview,
    unLikeReview,
    favProducts,
    totalFav,
    products,
    totalReviews,
    favoriteProducts,
    productReviews,
    categorys,
    units,
    total,
    isLoading,
  };
};

export default productHook;
