import { AxiosResponse } from 'axios';
import {
  IAddress, ICountry, ICreateAddress, IEditAddress, IProvince,
} from '../interface/Address';
import $axios from '../plugin/axios';

export const getAddress = async ():Promise<AxiosResponse<IAddress[]>> => {
  const response = $axios.get('/retailer-address');
  return response;
};

export const getCountry = async ():Promise<AxiosResponse<ICountry[]>> => {
  const response = $axios.post('/country');
  return response;
};

export const getProvince = async (countryId:string):Promise<AxiosResponse<IProvince[]>> => {
  const response = $axios.get(`/province/${countryId}`);
  return response;
};

export const createAddress = async (form:ICreateAddress):Promise<AxiosResponse> => {
  const response = $axios.post('/retailer-address', form);
  return response;
};

export const updateAddress = async (form:IEditAddress):Promise<AxiosResponse> => {
  const response = $axios.put('/retailer-address', form);
  return response;
};

export const deleteAddress = async (id:string):Promise<AxiosResponse> => {
  const response = $axios.delete('/retailer-address', { data: { id } });
  return response;
};
