/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
import { FC, useState } from 'react';
import { Button } from 'antd';
import { IOrder, IColumnTableOrderProp, IOrderDetail } from '../../interface/Order';
import './index.scss';

import OrderStatusBadge from '../order_status';
import { formatDateTime, formatMoney } from '../../common/hepler';
import orderHook from '../../hooks/order-hook';
import ModalReview from '../ModalReview';

type ITableOrderProps = {rows:IOrder[], onTableUpdate: () => void}
const TableOrder: FC<ITableOrderProps> = ({ rows, onTableUpdate }:ITableOrderProps) => {
  const { confirmReceived, fetchOrderDetail } = orderHook();
  const [orderByID, _orderByID] = useState<IOrderDetail>();
  const onOrderReceived = async (orderId:string) => {
    try {
      await confirmReceived(orderId);
      onTableUpdate();
    } catch (err) {
      alert('อัพเดทสถานะล้มเหลว');
    }
  };

  const onReviewClick = async (orderId:string) => {
    const data = await fetchOrderDetail(orderId);
    if (data && (data.orderStatus === '5' || data.orderStatus === '6')) {
      _orderByID(data);
    }
  };

  const columns = ():IColumnTableOrderProp[] => [
    {
      title: 'เลขที่คำสั่งซื้อ',
      index: '_id',
      width: '150px',
    },
    {
      title: 'วัน/เวลา',
      index: 'createdAt',
      width: '200px',
      render: (props: IOrder) => <div>{formatDateTime(props.createdAt)}</div>,
    },
    {
      title: 'ชื่อร้านค้า',
      index: 'wholesalerStoreName',
    },
    {
      title: 'ยอดรวม',
      index: 'totalPrice',
      align: 'center',
      render: (props: IOrder) => (
        <div style={{ fontWeight: 300 }}>
          {formatMoney(props.totalPrice)}
          {' '}
          บาท
        </div>
      ),
    },
    {
      title: 'สถานะ',
      index: 'orderStatus',
      render: (props: IOrder) => <OrderStatusBadge orderId={props._id} orderStatus={props.orderStatus} />,
      align: 'center',
    },
    {
      title: 'อัปเดต',
      index: 'updatedAt',
      width: '200px',
      render: (props: IOrder) => {
        const { orderStatus, updatedAt, _id } = props;
        if (orderStatus === '4') {
          return (
            <div style={{ textAlign: 'center' }}>
              <Button size="small" className="order__btn" onClick={() => onOrderReceived(_id)}>ฉันได้รับสินค้า</Button>
            </div>
          );
        } if (orderStatus === '5') {
          return <div style={{ textAlign: 'center' }}><Button size="small" className="order__btn" onClick={() => onReviewClick(_id)}>รีวิว</Button></div>;
        }
        return <div>{formatDateTime(updatedAt)}</div>;
      },
    },
  ];

  return (
    <div className="order__table">
      <table>
        <thead>
          <tr>
            {columns().map((column, cIndex) => (
              <th key={`column_${cIndex}`} style={{ flex: column.width ? `0 0 ${column.width}` : '1', textAlign: column.align || 'left' }}>
                <div>
                  {column.title}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? rows.map((row:IOrder, index:number) => (
            <tr key={`row_${index}`}>
              {columns().map((column, cRow) => (
                <td key={`key_${index}_${cRow}`} style={{ flex: column.width ? `0 0 ${column.width}` : '1', textAlign: column.align || 'left' }}>
                  {column.render ? column.render(row, index) : row[column.index]}
                </td>
              ))}
            </tr>
          )) : (
            <tr>
              <td> no data...</td>
            </tr>
          )}
        </tbody>
      </table>
      {orderByID !== undefined
        ? (
          <ModalReview
            isModalVisible={orderByID !== undefined}
            orderByID={orderByID}
            handleOk={() => { _orderByID(undefined); onTableUpdate(); }}
            handleCancel={() => { _orderByID(undefined); onTableUpdate(); }}
            onReviewed={(orderId) => onReviewClick(orderId)}
          />
        ) : null}
    </div>
  );
};

export default TableOrder;
