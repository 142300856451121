/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import { refreshTokenService } from '../services/auth-service';

const $axios = axios.create();
$axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL ?? '';

$axios.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  if (config.url === '/auth/renew-retailer') {
    config.headers = {
      Authorization: `Bearer ${refreshToken}`,
    };
  } else if (accessToken) {
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  }
  return config;
}, (error) => Promise.reject(error));

$axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const accessToken = localStorage.getItem('accessToken');
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry && accessToken) {
      try {
        originalRequest._retry = true;
        const { data } = await refreshTokenService(accessToken);
        localStorage.setItem('accessToken', data.accessToken);
        originalRequest.headers = {
          Authorization: `Bearer ${data.accessToken}`,
        };
        return $axios(originalRequest);
      } catch (err) {
        localStorage.clear();
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  },
);

export default $axios;
