/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import { Breadcrumb, Button } from 'antd';
import { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ArrowBackIcon from '../../common/icons/arrow_back';
import './index.scss';

export type IRouteProps ={
    path: string,
    title: string
}
type IBreadcrumbComponentProps = {
    routes: IRouteProps[]
}

const BreadcrumbComponent:FC<IBreadcrumbComponentProps> = ({ routes }:IBreadcrumbComponentProps) => {
  const router = useHistory();
  return (
    <div className="my__bread_crumb">
      <div className="back__button" role="button" tabIndex={0} onClick={() => router.goBack()}>
        <ArrowBackIcon />
      </div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        {routes.map((route:IRouteProps, index:number) => (
          <Breadcrumb.Item key={String(index)}>
            <Link to={route.path}>{route.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
};

export default BreadcrumbComponent;
