import Categories from './Categories';
import Recomend from './Recomend';
import BottomNavigation from '../../components/BottomNavigation';
import './index.scss';
import TopNavigation from '../../components/TopNavigation';
import Slides from './Slides';

const HomePage = (): JSX.Element => (
  <div className="HomePage">
    <div className="nav__section">
      <TopNavigation />
    </div>
    <div className="slide__section">
      <Slides />
    </div>
    <div className="body__section">
      <Categories />
      <Recomend />
    </div>
    <BottomNavigation />
  </div>
);

export default HomePage;
