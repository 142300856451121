/* eslint-disable react/jsx-props-no-spreading */
import Icon from '@ant-design/icons';
import { FC } from 'react';

const SVG:FC = () => (
  <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 2.5H15C15.825 2.5 16.5 3.0625 16.5 3.75V11.25C16.5 11.9375 15.825 12.5 15 12.5H3C2.175 12.5 1.5 11.9375 1.5 11.25V3.75C1.5 3.0625 2.175 2.5 3 2.5Z" stroke="#807FA5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.5 3.75L9 8.125L1.5 3.75" stroke="#807FA5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const EmailIcon: FC = (props) => <Icon component={SVG} {...props} />;

export default EmailIcon;
