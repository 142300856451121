/* eslint-disable react/jsx-props-no-spreading */
import Icon from '@ant-design/icons';
import { FC } from 'react';

const SVG:FC = () => (
  <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.0557 1.94554C15.6508 -0.648513 19.8581 -0.648513 22.4532 1.94554C25.0484 4.53974 25.0484 8.7459 22.4532 11.3401L12.9945 20.7951C12.7211 21.0683 12.2781 21.0683 12.0047 20.7951L2.54603 11.3401C-0.049198 8.7459 -0.049198 4.53974 2.54603 1.94554C5.14111 -0.648513 9.34844 -0.648513 11.9435 1.94554L12.4996 2.50141L13.0557 1.94554Z" fill="#1B1D21" />
  </svg>

);

const NavHeartIcon: FC = (props) => <Icon component={SVG} {...props} />;

export default NavHeartIcon;
