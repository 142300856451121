/* eslint-disable max-len */
import { AxiosResponse } from 'axios';
import { ICountry, IProvince } from '../interface/Address';
import { VerifyResponse } from '../interface/Register';
import { ICreateRetailer, ICreateWholesaler } from '../interface/RegisterForm';
import { IUserLogin, IUserToken } from '../interface/User';
import $axios from '../plugin/axios';

export const loginService = async (form:IUserLogin):Promise<AxiosResponse<IUserToken>> => {
  const response = await $axios.post('/auth/signin-retailer', form);
  return response;
};

export const logoutService = async ():Promise<AxiosResponse | undefined> => {
  try {
    const response = await $axios.post('/auth/signout-retailer', { refreshToken: localStorage.getItem('refreshToken') });
    return response;
  } catch (err) {
    return undefined;
  }
};

export const refreshTokenService = async (accessToken:string):Promise<AxiosResponse> => {
  const response = $axios.get('/auth/renew-retailer', {
    data: {
      accessToken,
    },
  });
  return response;
};

export const verifyRetailerEmail = async (email:string):Promise<AxiosResponse<VerifyResponse>> => {
  const response = await $axios.post('/retailer/veryfy-email', {
    email,
  });
  return response;
};

export const verifyRetailerStoreName = async (storeName:string):Promise<AxiosResponse<VerifyResponse>> => {
  const response = await $axios.post('/retailer/veryfy-storename', {
    storeName,
  });
  return response;
};

export const verifyWholesalerEmail = async (email:string):Promise<AxiosResponse<VerifyResponse>> => {
  const response = await $axios.post('/wholesaler/veryfy-email', {
    email,
  });
  return response;
};

export const verifyWholesalerStoreName = async (storeName:string):Promise<AxiosResponse<VerifyResponse>> => {
  const response = await $axios.post('/wholesaler/veryfy-storename', {
    storeName,
  });
  return response;
};

export const getCountry = async ():Promise<AxiosResponse<ICountry[]>> => {
  const response = await $axios.get('/country');
  return response;
};

export const getProvince = async (countryId:string):Promise<AxiosResponse<IProvince[]>> => {
  const response = await $axios.get(`/province/${countryId}`);
  return response;
};

export const registerWholesaler = async (form:ICreateWholesaler):Promise<AxiosResponse<IUserToken>> => {
  const response = await $axios.post('/auth/signup-wholesaler', form);
  return response;
};

export const registerRetailer = async (form:ICreateRetailer):Promise<AxiosResponse<IUserToken>> => {
  const response = await $axios.post('/auth/signup-retailer', form);
  return response;
};
