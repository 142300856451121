/* eslint-disable react/jsx-props-no-spreading */
import Icon from '@ant-design/icons';
import { FC } from 'react';

const SVG:FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#E4E5E7" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16 16.1239C18.651 16.1239 20.8 13.9749 20.8 11.3239C20.8 8.67296 18.651 6.52393 16 6.52393C13.349 6.52393 11.2 8.67296 11.2 11.3239C11.2 13.9749 13.349 16.1239 16 16.1239ZM16 28.9239C20.0332 28.9239 23.637 27.0485 25.9849 24.124C23.637 21.1994 20.0332 19.3239 15.9998 19.3239C11.9665 19.3239 8.36282 21.1993 6.01489 24.1238C8.36282 27.0484 11.9666 28.9239 16 28.9239Z" fill="black" />
  </svg>

);

const NavAccountIcon: FC = (props) => <Icon component={SVG} {...props} />;

export default NavAccountIcon;
