/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import {
  Button, Divider, Select, Space,
} from 'antd';
import { useParams } from 'react-router-dom';
import productHook from '../../hooks/product-hook';
import ListProductSearch from './Product';
import './index.scss';
import { useQuery } from '../../common/hepler';

const { Option } = Select;

const CategoryPage = (): JSX.Element => {
  const query = useQuery();
  const keyword = query.get('keyword');
  const { categoryId }: { categoryId: string } = useParams();

  const { fetchProduct, products, total } = productHook();

  const [page, _page] = useState(1);
  const size = 10;
  const [sort, _sort] = useState('hp');

  let allCatFlag = categoryId === 'all' || categoryId === undefined ? '' : `category=${categoryId}`;
  if (keyword !== null) {
    allCatFlag = allCatFlag === '' ? `keyword=${keyword}` : `category=${categoryId}&keyword=${keyword}`;
  }

  const fetchData = async () => {
    await fetchProduct(allCatFlag || '', 1, 10, 'hp');
    _page(1);
    _sort('hp');
  };

  useEffect(() => {
    fetchData();
  }, [categoryId, keyword]);

  const nowPage = page * size;

  return (
    <div className="CategoryPage">
      <Space style={{ paddingLeft: '150px', color: '#535252' }}>
        <div>
          <span style={{ color: '#ff0000', marginRight: 4 }}>ALL</span>
          <span>PRODUCT FOUND</span>
        </div>
        <div>
          <Space>
            <div>เรียงโดย : </div>
            <Select
              value={sort}
              onChange={(val) => {
                fetchProduct(allCatFlag || '', 1, size, val);
                _sort(val);
                _page(1);
              }}
            >
              <Option value="hp">ราคาจากมากไปน้อย</Option>
              <Option value="lp">ราคาจากน้อยไปมาก</Option>
              <Option value="hr">เรทติ้งจากมากไปน้อย</Option>
              <Option value="lr">เรทติ้งจากน้อยไปมาก</Option>
            </Select>
          </Space>
        </div>
      </Space>
      {products.length > 0 ? <div style={{ marginTop: 20 }}><ListProductSearch products={products} /></div> : (
        <h3 style={{ marginTop: 70, textAlign: 'center', color: '#535252' }}>
          ไม่พบข้อมูล...
        </h3>
      )}
      {nowPage >= total ? null
        : (
          <div className="more__btn">
            <Divider>
              <Button onClick={() => {
                const newPage = page + 1;
                fetchProduct(allCatFlag || '', newPage, size, sort);
                _page(newPage);
              }}
              >
                ดูเพิ่มเติม
              </Button>
            </Divider>
          </div>
        )}
    </div>
  );
};

export default CategoryPage;
