import { FC, useEffect, useState } from 'react';
import './index.scss';
import { Steps } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import orderHook from '../../hooks/order-hook';
import { IOrderDetail } from '../../interface/Order';
import StepOrderReceipt from '../../components/StepOrderPayment/Receipt';

const { Step } = Steps;

type OrderPaymentParams = {
  orderId:string
}
const OrderReceipt:FC = () => {
  const { orderId } : OrderPaymentParams = useParams();
  const [step, _step] = useState<number>(3);
  const [orderByID, _orderByID] = useState<IOrderDetail>();
  const router = useHistory();
  const { fetchOrderDetail } = orderHook();

  const fetchData = async () => {
    const data = await fetchOrderDetail(orderId);
    if (data && (data.orderStatus === '2' || data?.orderStatus === '3' || data?.orderStatus === '4' || data?.orderStatus === '5' || data?.orderStatus === '6')) {
      _orderByID(data);
    } else {
      router.goBack();
    }
  };

  useEffect(() => {
    if (orderId) {
      fetchData();
    }
  }, [orderId]);

  return (
    <div className="order__payment__page">

      <Steps size="small" current={step} labelPlacement="vertical">
        <Step subTitle="รอร้านค้ายืนยันคำสั่งซื้อ" />
        <Step subTitle="ชำระเงิน" />
        <Step subTitle="ใบเสร็จ" />
      </Steps>

      <div>
        <StepOrderReceipt onNextStep={() => router.goBack()} onGoHomePage={() => router.push('/')} order={orderByID} />
      </div>

    </div>
  );
};

export default OrderReceipt;
