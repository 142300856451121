/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import {
  Switch, Route, BrowserRouter,
} from 'react-router-dom';
import HomePage from './containers/HomePage';
import './App.scss';
import Register from './containers/Register';
import ForgotPassword from './containers/ForgotPassword';
import Favorite from './containers/Favorite';
import Search from './containers/Search';
import RegisterWholesalerForm from './containers/RegisterForm/wholesaler';
import Cart from './containers/Cart';
import RouteWithAuth from './routes/route-with-layout';
import Order from './containers/Order';
import OrderPayment from './containers/Order/order-payment';
import OrderReceipt from './containers/Order/order-receipt';
import ProductDetail from './containers/Product/id';
import { useAppSelector } from './reducers/store';
import LoadingApp from './components/loadingApp';
import orderCancel from './containers/Order/order-cancel';
import ModalLogin from './components/ModalLogin';
import ModalRegister from './components/ModalRegister';
import RegisterRetailerForm from './containers/RegisterForm/retailer';
import RegisterComplete from './containers/RegisterForm/complete';
import CartCheckout from './containers/Cart/checkout';
import CategoryPage from './containers/Category';

const App = (): JSX.Element => {
  const isLoading = useAppSelector((state) => {
    let k: keyof typeof state;
    const isLoading = [];
    for (k in state) {
      const value = state[k];
      isLoading.push(value?.isLoading);
    }
    return isLoading.some((q) => q === true);
  });
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/register/form/retailer" component={RegisterRetailerForm} />
          <Route exact path="/register/form/wholesaler" component={RegisterWholesalerForm} />
          <Route exact path="/register/complete" component={RegisterComplete} />
          <RouteWithAuth path="/search" component={Search} />
          <RouteWithAuth path="/category/:categoryId" component={CategoryPage} />
          <RouteWithAuth path="/register" component={Register} />
          <RouteWithAuth path="/forgotpassword" component={ForgotPassword} />
          <RouteWithAuth path="/favorite" component={Favorite} />
          <RouteWithAuth path="/cart" component={Cart} />
          <RouteWithAuth path="/cart-checkout" component={CartCheckout} />
          <RouteWithAuth path="/order" component={Order} />
          <RouteWithAuth path="/order-payment/:orderId" component={OrderPayment} />
          <RouteWithAuth path="/order-cancel/:orderId" component={orderCancel} />
          <RouteWithAuth path="/order-receipt/:orderId" component={OrderReceipt} />
          <RouteWithAuth path="/product/:productId" component={ProductDetail} />
        </Switch>
        <ModalLogin />
        <ModalRegister />
        {isLoading ? <LoadingApp /> : null}
      </BrowserRouter>
    </div>
  );
};

export default App;
