/* eslint-disable  */
import { Table, Space, Row, Col } from 'antd';
import { formatMoney } from '../../common/hepler';
import { ICart, ICartProduct } from '../../interface/Cart';
import './index.scss'
const columns = [
  {
    title: 'สินค้า',
    dataIndex: 'product',
    width: 260,
    render: (_: any, record: ICartProduct) =>
      <div>
        <Row gutter={[12, 24]} wrap={false}>
          <Col flex="50px">
            <div style={{
              backgroundImage: `url(${record?.product.images[0]?.url || ''})`, backgroundSize: 'cover', width: 50, height: 50,
            }}
            />
          </Col>
          <Col flex="120px">
            <div className="product_name">
              {record?.product.product}
            </div>
            <div className="product_variant">
              {record?.variantSaleInfo.var1.name}
            </div>
            <div className="product_variant">
              {record?.variantSaleInfo.var2?.name || ''}
            </div>
          </Col>
        </Row>
      </div>
  },
  {
    title: 'จำนวน',
    align: 'center' as const,
    width: 20,
    render: (_: any, record: ICartProduct) => <div>{formatMoney(record.amount, 0)}</div>
  },
  {
    title: 'ราคา',
    align: 'center' as const,
    width: 30,
    render: (_: any, record: ICartProduct) => <div>{formatMoney(record.variantSaleInfo.price, 0)}</div>
  },
  {
    title: 'ราคารวม',
    align: 'center' as const,
    width: 100,
    render: (_: any, record: ICartProduct) => <div>{formatMoney(record.variantSaleInfo.price * record.amount, 0)}</div>
  },
];

type ITableConfirm = {
  cart: ICart
}
const TableConfirm = ({ cart }: ITableConfirm): JSX.Element => (
  <div className='CartCard'>
    <div className='header-table'>
      {cart.storeName}
    </div>
    <Table columns={columns} dataSource={cart.list} />
  </div>

);

export default TableConfirm;
