/* eslint-disable max-len */
import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './index.scss';

import { Button } from 'antd';
import orderHook from '../../hooks/order-hook';
import { IOrderDetail } from '../../interface/Order';

type OrderPaymentParams = {
    orderId:string
}
const OrderCancel:FC = () => {
  const { orderId } : OrderPaymentParams = useParams();
  const [orderByID, _orderByID] = useState<IOrderDetail>();
  const router = useHistory();
  const { fetchOrderDetail } = orderHook();

  const fetchData = async () => {
    const data = await fetchOrderDetail(orderId);
    if (data && data.orderStatus === '10') {
      _orderByID(data);
    } else {
      router.goBack();
    }
  };

  useEffect(() => {
    if (orderId) {
      fetchData();
    }
  }, [orderId]);

  return (
    <div className="order__cancel__page">
      <div className="reason__box">
        <div>
          เหตุผลการยกเลิก
        </div>
        <div>
          {orderByID?.reason || ''}
        </div>
      </div>
      <div className="text-right">
        <Button className="goto__home__btn" size="large" onClick={() => router.push('/')}>กลับไปหน้าหลัก</Button>
        <Button className="goto__order__btn" size="large" onClick={() => router.goBack()}>กลับไปหน้ารายการสินค้า</Button>
      </div>
    </div>
  );
};

export default OrderCancel;
