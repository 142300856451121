/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOrder, IOrderResponse } from '../../interface/Order';

// Define a type for the slice state
interface OrderState {
  orders: IOrder[];
  total: number;
  isLoading: boolean
}

// Define the initial state using that type
const initialState: OrderState = {
  orders: [],
  total: 0,
  isLoading: false,
};

export const orderSlice = createSlice({
  name: 'order',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setOrder(state, action: PayloadAction<IOrderResponse>) {
      return {
        ...state,
        orders: action.payload.docs,
        total: action.payload.total,
      };
    },
    addOrder(state, action: PayloadAction<IOrderResponse>) {
      const addOrder = [...state.orders, ...action.payload.docs];
      return {
        ...state,
        orders: addOrder,
        total: action.payload.total,
      };
    },
    setLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    updateReceivedOrder(state, action: PayloadAction<string>) {
      const currentArray = [...state.orders];
      const indexUpdate = state.orders.findIndex((i) => i._id === action.payload);
      const update: IOrder = { ...currentArray[indexUpdate], orderStatus: '5' };
      const updateSlice = [...currentArray.slice(0, indexUpdate), update, ...currentArray.slice(indexUpdate + 1)];
      return {
        ...state,
        orders: updateSlice,
      };
    },
  },
});

export const {
  setOrder, addOrder, setLoading, updateReceivedOrder,
} = orderSlice.actions;

export default orderSlice.reducer;
