/* eslint-disable max-len */
import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Steps } from 'antd';
import './index.scss';

import StepOrderConfirm from '../../components/StepOrderPayment/confirm';
import StepOrderInform from '../../components/StepOrderPayment/inform';
import StepOrderReceipt from '../../components/StepOrderPayment/Receipt';
import orderHook from '../../hooks/order-hook';
import { IOrderDetail } from '../../interface/Order';

const { Step } = Steps;

type OrderPaymentParams = {
    orderId:string
}
const OrderPayment:FC = () => {
  const { orderId } : OrderPaymentParams = useParams();
  const [step, _step] = useState<number>(0);
  const [orderByID, _orderByID] = useState<IOrderDetail>();
  const router = useHistory();
  const { fetchOrderDetail } = orderHook();

  const fetchData = async () => {
    const data = await fetchOrderDetail(orderId);
    if (data && (data.orderStatus === '1' || data?.orderStatus === '0')) {
      _orderByID(data);
    } else {
      router.goBack();
    }
  };

  const stepBody = () => {
    let body;
    if (step === 0) {
      body = <StepOrderConfirm canNext={orderByID?.orderStatus === '1'} onNextStep={() => { _step(1); }} orders={orderByID?.orders || []} storeName={orderByID?.wholesalerStoreName} />;
    } else if (step === 1) {
      body = (
        <StepOrderInform
          onNextStep={async () => {
            const data = await fetchOrderDetail(orderId);
            _orderByID(data);
            _step(2);
          }}
          orderByID={orderByID}
          onBackStep={() => _step(0)}
        />
      );
    } else if (step === 2) {
      body = <StepOrderReceipt onNextStep={() => router.push('/order')} onGoHomePage={() => router.push('/')} order={orderByID} />;
    } else {
      body = <div>-</div>;
    }
    return body;
  };

  useEffect(() => {
    if (orderId) {
      fetchData();
    }
  }, [orderId]);

  return (
    <div className="order__payment__page">

      <Steps size="small" current={step} labelPlacement="vertical">
        <Step subTitle="รอร้านค้ายืนยันคำสั่งซื้อ" />
        <Step subTitle="ชำระเงิน" />
        <Step subTitle="ใบเสร็จ" />
      </Steps>

      <div>
        {stepBody()}
      </div>

    </div>
  );
};

export default OrderPayment;
