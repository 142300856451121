import { Button } from 'antd';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { IOrderStatus, ITextOrderStatus } from '../interface/Order';

export const OrderStatus:ITextOrderStatus[] = [
  {
    label: 'รอร้านค้ายืนยันคำสั่งซื้อ',
    orderStatus: '0',
    color: '#EF7A44',
  },
  {
    label: 'รอชำระเงิน',
    orderStatus: '1',
    color: '#F0C94E',
  },
  {
    label: 'ชำระแล้ว',
    orderStatus: '2',
    color: '#62CE85',
  },
  {
    label: 'รอการจัดส่ง',
    orderStatus: '3',
    color: '#56A675',
  },
  {
    label: 'จัดส่งสำเร็จ',
    orderStatus: '4',
    color: '#56A675',
  },
  {
    label: 'ได้รับสินค้าแล้ว',
    orderStatus: '5',
    color: '#56A675',
  },
  {
    label: 'สำเร็จ',
    orderStatus: '6',
    color: '#56A675',
  },
  {
    label: 'ยกเลิก',
    orderStatus: '10',
    color: '#EE5252',
  },
];

type IOrderStatusProp = {
   orderId: string
} & IOrderStatus
const OrderStatusBadge: FC<IOrderStatusProp> = ({ orderStatus, orderId } : IOrderStatusProp) => {
  const router = useHistory();
  const findStatus = OrderStatus.find((q) => q.orderStatus === orderStatus);
  const styled = {
    backgroundColor: findStatus?.color,
    color: 'white',
    fontSize: '12px',
    fontFamily: 'Poppins',
    border: 'none',
    borderRadius: '0px',
    borderColor: findStatus?.color,
    minWidth: '80px',
    width: '100%',
    maxWidth: '200px',
  };

  const toOrder = () => {
    window.scroll(0, 0);
    if (orderStatus === '1' || orderStatus === '0') {
      router.push(`/order-payment/${orderId}`);
    } else if (orderStatus === '10') {
      router.push(`/order-cancel/${orderId}`);
    } else if (orderStatus === '2' || orderStatus === '3' || orderStatus === '4' || orderStatus === '5' || orderStatus === '6') {
      router.push(`/order-receipt/${orderId}`);
    }
  };

  return (
    <Button onClick={toOrder} size="small" style={styled}>
      <div style={{ paddingTop: 1 }}>
        {findStatus?.label}
      </div>
    </Button>
  );
};

export default OrderStatusBadge;
