import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAddress } from '../../interface/Address';
import { ICart, ISummaryCart } from '../../interface/Cart';

// Define a type for the slice state
interface AddressState {
  address: IAddress[];
  isLoading: boolean
}

// Define the initial state using that type
const initialState: AddressState = {
  address: [],
  isLoading: false,
};

export const addressSlice = createSlice({
  name: 'address',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAddress(state, action: PayloadAction<IAddress[]>) {
      return {
        ...state,
        address: action.payload,
      };
    },
    setLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
  },
});

export const { setAddress, setLoading } = addressSlice.actions;

export default addressSlice.reducer;
