import { Col, Row } from 'antd';
import CardProductSearch from '../../../components/CardProductSearch';
import { IProduct } from '../../../interface/Product';

const ListProductSearch = ({ products }:{products:IProduct[]}) => (
  <Row gutter={[24, 24]}>
    {products.map((prod, index) => <Col key={`prod_srch_${String(index)}`} span={24} xs={24} md={24} lg={12}><CardProductSearch product={prod} /></Col>)}
  </Row>
);

export default ListProductSearch;
