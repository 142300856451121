import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useHistory } from 'react-router';
import './index.scss';

const RegisterComplete = (): JSX.Element => {
  const router = useHistory();
  return (
    <>
      <LeftOutlined
        onClick={() => {
          router.push('/');
        }}
        style={{ margin: '75px 0 0 65px', position: 'absolute', fontSize: '20px' }}
      />
      <div className="RegisterComplete">
        <div className="header">
          รอการยืนยันการสมัคร
        </div>
        <div className="detail">
          ขอบคุณที่สมัครสมาชิก แอดมินจะส่ง Email ยืนยันการสมัครสมาชิก ภายใน1-2วันทำการ
        </div>
        <div className="btn">
          <Button onClick={() => {
            router.push('/');
          }}
          >
            กลับไปหน้าหลัก

          </Button>
        </div>
      </div>
    </>
  );
};

export default RegisterComplete;
