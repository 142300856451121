/* eslint-disable react/jsx-props-no-spreading */
import Icon from '@ant-design/icons';
import { FC } from 'react';

const SVG:FC = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.5" d="M7.91666 0.97644C5.62091 0.97644 3.75316 2.84419 3.75316 5.13994C3.75316 7.43569 5.62091 9.30344 7.91666 9.30344C10.2124 9.30344 12.0802 7.43569 12.0802 5.13994C12.0802 2.84419 10.2124 0.97644 7.91666 0.97644ZM7.91666 7.63804C6.53937 7.63804 5.41856 6.51722 5.41856 5.13994C5.41856 3.76265 6.53937 2.64184 7.91666 2.64184C9.29394 2.64184 10.4148 3.76265 10.4148 5.13994C10.4148 6.51722 9.29394 7.63804 7.91666 7.63804ZM14.5783 16.7977C15.0381 16.7977 15.411 16.4249 15.411 15.965V15.965C15.411 12.7516 12.7954 10.1361 9.58206 10.1361H6.25126C3.03704 10.1361 0.422363 12.7516 0.422363 15.965V15.965C0.422363 16.4249 0.795176 16.7977 1.25506 16.7977V16.7977C1.71495 16.7977 2.08776 16.4249 2.08776 15.965V15.965C2.08776 13.6693 3.95551 11.8015 6.25126 11.8015H9.58206C11.8778 11.8015 13.7456 13.6693 13.7456 15.965V15.965C13.7456 16.4249 14.1184 16.7977 14.5783 16.7977V16.7977Z" fill="#03014C" />
  </svg>

);

const AccountEmailIcon: FC = (props) => <Icon component={SVG} {...props} />;

export default AccountEmailIcon;
