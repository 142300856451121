/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-alert */
/* eslint-disable max-len */
import {
  Button, Form, Input, Modal, Radio,
} from 'antd';
import React, { FC, useRef } from 'react';
import { Link } from 'react-router-dom';
import AccountEmailIcon from '../../common/icons/account_email';
import AccountPasswordIcon from '../../common/icons/account_icon';
import LogoIcon from '../../common/icons/logo_icon';
import AuthHook from '../../hooks/auth-hook';
import CartHook from '../../hooks/cart-hook';
import productHook from '../../hooks/product-hook';
import { IUserLogin } from '../../interface/User';
import './index.scss';

const ModalLogin: FC = () => {
  const {
    isModalLoginVisible,
    setVisibleModalLogin,
    setVisibleModalRegister,
    login,
    clearLoading,
  } = AuthHook();
  const { fetchProductRecommed } = productHook();
  const { fetchCart } = CartHook();
  const [form] = Form.useForm();

  const handleCancel = () => {
    setVisibleModalLogin(false);
    form.resetFields();
  };

  const onFinish = async (values: IUserLogin) => {
    try {
      await login(values);
      await fetchProductRecommed();
      await fetchCart();
      setVisibleModalLogin(false);
    } catch (err) {
      alert('Login failed');
      clearLoading();
      form.resetFields();
    }
  };

  const onFinishFailed = async () => {
    console.log('failed');
  };

  const registerClick = () => {
    handleCancel();
    setTimeout(() => {
      setVisibleModalRegister(true);
    }, 300);
  };

  console.log(process.env.REACT_APP_WHOLESALER_URL);

  return (
    <Modal
      title={null}
      footer={null}
      visible={isModalLoginVisible}
      onCancel={handleCancel}
      closable={false}
    >
      <div className="login_modal">
        <LogoIcon />
        <h2>เข้าสู่ระบบ</h2>
        <p>สินค้าราคาส่งมากมายรอคุณอยู่</p>
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'กรุณากรอก Email ให้ถูกต้อง !',
              },
              {
                required: true,
                message: 'กรุณากรอก Email !',
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Enter your email"
              className="input_login"
              prefix={<AccountEmailIcon />}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน' }]}
          >
            <Input.Password
              size="large"
              placeholder="Password"
              className="input_login"
              prefix={<AccountPasswordIcon />}
            />
          </Form.Item>
          <div className="remember_section">
            <Radio>Remember me</Radio>
            <div className="register_btn">
              <a aria-hidden onClick={registerClick}>
                สมัครสมาชิก
              </a>
            </div>
          </div>
          <Button htmlType="submit" className="login_btn">
            เข้าสู่ระบบแบบลูกค้า
          </Button>
          <div className="whole_btn">
            <a href={process.env.REACT_APP_WHOLESALER_URL} target="_blank">
              คลิกที่นี่เพื่อเข้าสู่ระบบแบบร้านค้า
            </a>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalLogin;
