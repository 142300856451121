/* eslint-disable no-useless-catch */
/* eslint-disable no-underscore-dangle */
import * as addressSlice from '../reducers/state/address.slice';
import * as addressService from '../services/address-service';
import { useAppDispatch, useAppSelector } from '../reducers/store';
import { ICreateAddress, IEditAddress } from '../interface/Address';

const AddressHook = () => {
  const { isLoading, address } = useAppSelector((state) => state.address);
  const dispatch = useAppDispatch();

  const getAddressById = (addressId:string) => address.find((q) => q._id === addressId);

  const setLoading = (flag:boolean) => dispatch(addressSlice.setLoading(flag));

  const fetchAddress = async () => {
    setLoading(true);
    const { data } = await addressService.getAddress();
    dispatch(addressSlice.setAddress(data));
    setLoading(false);
  };

  const createAddress = async (form:ICreateAddress) => {
    try {
      setLoading(true);
      const { data } = await addressService.createAddress(form);
      return data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const editAddress = async (form:IEditAddress) => {
    try {
      setLoading(true);
      const { data } = await addressService.updateAddress(form);
      return data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const deleteAddress = async (id:string) => {
    try {
      setLoading(true);
      const { data } = await addressService.deleteAddress(id);
      return data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    fetchAddress,
    createAddress,
    editAddress,
    deleteAddress,
    getAddressById,
    address,
    isLoading,
  };
};

export default AddressHook;
