/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser, IUserToken } from '../../interface/User';

// Define a type for the slice state
interface AuthState {
  isLoading: boolean
  isModalLoginVisible: boolean
  isModalRegisterVisible:boolean
  me?: IUser
  accessToken?: string | undefined
  refreshToken?:string | undefined
  registerEmail?: string
}

// Define the initial state using that type
const initialState: AuthState = {
  me: undefined,
  isModalLoginVisible: false,
  isModalRegisterVisible: false,
  isLoading: false,
  accessToken: localStorage.getItem('accessToken') || undefined,
  refreshToken: localStorage.getItem('refreshToken') || undefined,
  registerEmail: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    setVisibleModalLogin(state, action:PayloadAction<boolean>) {
      return {
        ...state,
        isModalLoginVisible: action.payload,
      };
    },
    setVisibleModalRegister(state, action:PayloadAction<boolean>) {
      return {
        ...state,
        isModalRegisterVisible: action.payload,
      };
    },
    setToken(state, action:PayloadAction<{accessToken: string | undefined; refreshToken:string | undefined;}>) {
      const { accessToken } = action.payload;
      const { refreshToken } = action.payload;
      return {
        ...state,
        accessToken,
        refreshToken,
      };
    },
    setRegisterEmail(state, action:PayloadAction<string>) {
      return {
        ...state,
        registerEmail: action.payload,
      };
    },
  },
});

export const {
  setLoading, setVisibleModalLogin, setVisibleModalRegister, setToken, setRegisterEmail,
} = authSlice.actions;

export default authSlice.reducer;
